import React from "react";
import { Link } from "react-router-dom";
import OnpageSeo from "../images/digitalmarketing/0n page Seo.jpeg";
import OffPageSeo from "../images/digitalmarketing/0ff page seo.jpeg";
import LocalSeo from "../images/digitalmarketing/local seo.jpeg";
import TechnicalSeo from "../images/digitalmarketing/Free Vector _ Analysis concept illustration.jpeg";
import SMO from "../images/digitalmarketing/red 4040136.jpeg";

import TopCompany from "../images/digitalmarketing/org.jpeg";
import Tick from "../images/checkbox (1).png";
import { Helmet } from "react-helmet";
const Digitalmarketing = () => {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Digital Marketing Company in Madurai</title>
          <meta name="description" content="FindWay Digital is a trusted digital marketing company in Madurai. Our team of digital marketing experts is dedicated to helping businesses establish a strong online presence and achieve their marketing goals." />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
          />
        </Helmet>
      </div>
      <section>
        <section>
          <div className="pb-10">
            <section class="wrapperTwo pb-10">
              <div id="stars1"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>
              <div className="w-full h-auto ">
                <div className="flex w-auto h-auto auto max-md:flex-col ">
                  <div className="lg:w-[50%] h-auto md:auto font-RobatoCondesed">
                    <h1 className="pl-10 pt-10 pb-2 uppercase text-white   text-[35px] font-semibold ">
                      <span className="text-gradient  ">Digital </span>
                      Marketing
                    </h1>
                    <div className="pb-5">
                      <ul className="flex h-8 pl-10 space-x-2 text-white">
                        <li className="flex items-center text-[12px] ">
                          <Link to={"/"}> HOME </Link>
                          <span className="ml-2 text-gray-400 text-[12px]">
                            /
                          </span>
                        </li>
                        <li className="flex items-center text-[12px] ">
                          Digital Marketing
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="w-full h-auto pt-16 pb-2 lg:pl-10 lg:pr-28 max-md:px-5 max-md:pt-3 md:px-3">
                    <div className="flex lg:w-[100%] h-auto rounded-full shadow-md lg:p-2 md:p-2 bg-slate-200 shadow-red-00 max-md:p-2">
                      <div className="h-auto p-2 text-white bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full max-md:w-[45%] lg:w-[25%]  text-center font-se md:w-[30%]">
                        Great
                      </div>
                      <div className="overflow-hidden">
                        <div className="pt-2 pl-2 font-medium max-sm:pt-1 marquee">
                          <p>
                            Digital Marketing Services, which is located in
                            Madurai
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full h-auto mt-5 font-RobatoCondesed">
                  <h1 className="text-center  uppercase font-sevenhundered max-sm:text-[25px] text-[35px] px-3 text-white">
                    Your Trusted Digital Marketing Company in
                    <span className="text-gradient  ml-2 mr-2 ">
                    Madurai
                    </span>
                    in Transformative Online Success
                  </h1>
                  <p className="px-6 text-[15px] mt-7 text-center text-white">
                    Digital marketing services provide businesses of all sizes
                    with an opportunity to market their brand 24/7 at a low
                    cost. From startups to medium-sized enterprises to
                    multiple-location companies, a digital marketing company
                    helps you expand your niche market reach to offer goods and
                    services to your target customers, irrespective of time
                    differences or location.
                  </p>
                  <p className="px-6 text-white text-[15px]  mt-7 text-center  ">
                    Hiring an internet marketing agency is one of the best ways
                    to reach your prospects while maintaining a robust
                    relationship with your existing clients. As long as your
                    business has a strong digital presence, your customers will
                    always find you.
                  </p>
                  <p className="px-6 text-white text-[15px] mt-7 text-center">
                    our digital marketing company has been partnering with
                    hundreds of businesses in the United States to achieve their
                    conversion goals. Throughout the years, we’ve provided an
                    array of custom digital marketing services to our clients
                    and generated the following results:
                  </p>
                </div>
                <div className="text-center">
                  <Link to={"/about-us"}><button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  w-[25%] text-3xl mt-10 max-sm:w-[50%] ">
                    
                    <span className="font-semibold ">About Us </span>
                    
                    
                  </button></Link>
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>

      {/* ----------- */}
      <div>
        <div className="px-16 font-RobatoCondesed max-md:px-6">
          <div>
            <h1 className="mt-10 uppercase text-[35px] text-justify ">
              WHAT IS
              <span className="ml-3 font-serif text-gradient ">
                DIGITAL MARKETING?
              </span>
            </h1>

            <p className="mt-5 font-normal text-justify  text-[18px]">
              The approach that prospective clients see your company online is
              impacted by digital marketing. Most of your potential customers
              won't know you exist if you don't use internet marketing! As an
              entrepreneur or business owner, it is your responsibility to
              ensure that your company can be readily identified online and in
              all of the digital media that are available to you. You should get
              assistance from a digital marketing company like Known Digital
              Marketing if this is outside of your area of knowledge.
            </p>
          </div>
          <div className="font-RobatoCondesed">
            <h1 className="mt-10 text-[35px]  text-justify ">
              WHY
              <span className="font-serif ml-3 mr-3 text-gradient ">
                DIGITAL MARKETING
              </span>
              IS IMPORTANT ?
            </h1>

            <p className="mt-5 font-normal text-justify  text-[18px]">
              Digital marketing is important for its global reach,
              cost-effectiveness, and precise targeting capabilities, allowing
              businesses to connect with specific audiences. Its measurable
              results offer insights for informed decision-making, and the
              interactivity it provides enhances customer engagement and brand
              experiences. With adaptability to changing trends, digital
              marketing ensures businesses stay competitive in the dynamic
              landscape, gaining a crucial edge in today's market.
            </p>
          </div>
        </div>
      </div>
      {/* ----------- */}
      <section>
        <div className="px-16 mt-28 max-md:px-6">
          <div>
            <h1 className=" text-center uppercase text-[35px] ">
              Our Digital
              <span className="font-serif ml-3 mr-3 text-gradient ">
                Marketing Services
              </span>
              In Madurai
            </h1>
          </div>
        </div>
      </section>
      {/* ----------- */}
      <section>
        <section className="mx-10 mt-20 max-md:px-6">
          <div className="flex flex-col justify-center ">
            <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 sm:grid-cols-2">
              {/* ---------- */}
              <div className="text-gray-700 flex flex-col rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={OnpageSeo}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki uppercase">
                  ON Page SEO
                </h2>

                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    Optimize your website for search engines and enhance the
                    user experience with our precision-focused On-Page SEO
                    strategies. lo
                  </p>
                </div>
                <div className="px-5 h-auto pb-5 mt-6 w-auto">
                  <Link to={"/on-page-seo"}>
                    {" "}
                    <button class="btn-hover w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                      Read more
                    </button>{" "}
                  </Link>
                </div>
              </div>

              {/* --------- */}
              <div className="text-gray-700 flex flex-col rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={OffPageSeo}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki">
                  OFF PAGE SEO
                </h2>
                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    Off-page SEO is the term for SEO strategies used by a
                    website to raise its search engine rankings.
                  </p>
                </div>
                <div className="px-5 mt-6 h-auto pb-5 w-auto">
                  {" "}
                  <Link to={"/off-page-seo"}>
                    {" "}
                    <button class="btn-hover   w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                      Read more
                    </button>{" "}
                  </Link>
                </div>
              </div>
              {/* --------- */}
              <div className="text-gray-700 flex flex-col rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={LocalSeo}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki uppercase">
                  LOCAL SEO
                </h2>
                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    Local SEO is a type of search engine optimization (SEO) that
                    makes your company visible in Google's local search results.
                  </p>
                </div>
                <div className="px-5 mt-6  h-auto pb-5 w-auto">
                  <Link to={"/local-page-seo"}>
                    <button class="btn-hover  w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                      Read more
                    </button>
                  </Link>
                </div>
              </div>

              {/* --------- */}
              <div className="text-gray-700 flex flex-col mt-16 rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={TechnicalSeo}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki uppercase">
                  {" "}
                  Technical SEO
                </h2>
                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    The purpose of technical SEO is to boost a website's organic
                    rank by making sure it follows the technical standards of
                    current search engines.
                  </p>
                </div>
                <div className="px-5 mt-6 h-auto pb-5 w-auto">
                  <Link to={"/technical-seo"}>
                    <button class="btn-hover w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                      Read more
                    </button>
                  </Link>
                </div>
              </div>

              {/* --------- */}
              <div className="text-gray-700 flex flex-col mt-16 rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={SMO}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki uppercase">
                  {" "}
                  SMO
                </h2>
                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    Boost your brand's social presence and connectivity with our
                    specialized social media optimization (SMO) services.
                  </p>
                </div>
                <div className="px-5 mt-6 h-auto pb-5 w-auto">
          
                  <button class="btn-hover w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                    Read more
                  </button>
                </div>
              </div>

              {/* ------- */}
              <div className="text-gray-700 flex flex-col mt-16 rounded-md shadow-lg shadow-gray-400">
                <div>
                  <img
                    src={SMO}
                    alt="loading"
                    className="object-cover object-center w-full rounded-t-md h-72 dark:bg-gray-500"
                  />
                </div>
                <h2 className="text-3xl p-5 font-semibold  tracki uppercase">              
                  SMM
                </h2>
                <div className="flex flex-col h-full justify-between px-5 ">
                  <p className="font-RobatoCondesed text-[18px]">
                    Social media marketing (SMM) is a key digital marketing
                    strategy that delivers unique benefits for brands. m eget.
                  </p>
                </div>

                <div className="px-5 mt-6 h-auto pb-5 w-auto">
                  {" "}
                  <button class="btn-hover w-full color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                    Read more
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {/* ----- */}
      <section>
        <div className="px-16 mt-28 max-md:px-6">
          <div className="font-RobatoCondesed">
            <h1 className="mt-10 text-[35px]  text-center">
              HOW
              <span className="font-serif ml-3 mr-3  text-gradient  ">
                DIGITAL MARKETING
              </span>
              WORKS ?
            </h1>

            <p className="mt-5 font-normal text-justify  text-[18px]">
              It commences by gaining an intimate understanding of the target
              audience and delving into their behaviors, preferences, and online
              habits. Clear objectives are then defined, whether the goal is to
              heighten brand visibility, generate leads, or bolster sales.
              Channels are carefully selected, ranging from search engines and
              social media to email and content marketing. Content creation
              takes center stage, with a focus on crafting relevant and engaging
              materials that deeply resonate with the identified audience.
            </p>
          </div>
        </div>
      </section>
      {/* ----------- */}
      <section>
        <div className="px-16 mt-16 max-md:px-6">
          <div>
            <h1 className="mt-10 text-[35px] uppercase text-center">
              Most Trusted
              <span className="ml-2 font-serif mr-3 text-gradient ">
                Digital Marketing Company
              </span>
              In Madurai
            </h1>
          </div>
          <div className="mt-2 max-md:flex-col md:flex">
            <div className="w-[50%] font-RobatoCondesed font-normal text-justify   text-[18px] mt-10 max-md:w-full">
              <p>
                The most trusted digital marketing company Backed by a legacy of
                trust and a commitment to client success, we stand as a beacon
                of innovation and reliability in the digital landscape.
              </p>
              <br />
              <p>
                Our seasoned team of experts, driven by a passion for results,
                crafts bespoke strategies that elevate your brand's online
                presence.
              </p>
              <br />
              <p>
                With a proven track record, we've earned the trust of our
                clients through transparent communication, personalized
                solutions, and a relentless pursuit of excellence. Partner with
                us to experience a digital journey where trust meets innovation,
                ensuring your brand thrives in the dynamic world of online
                marketing.
              </p>
            </div>
            <div className="w-[50%] max-md:w-full max-md:mt-10 ">
              <img src={TopCompany} alt="loading" />
            </div>
          </div>
        </div>
      </section>
      {/* ------ */}
      <section className="mt-20 mb-10 ">
        <div className="w-full h-auto p-5 text-white bg-slate-900 ">
          <div className="mx-16 font-RobatoCondesed mt-10 mb-20 max-md:mx-0">
            <h1 className="text-[35px] uppercase text-center">
              Connecting Brands and Customers with
              <span className="ml-2 text-gradient ">
                Expert Digital Marketing Services
              </span>
            </h1>

            <p className="mt-5 text-lg text-center">
              Join forces with "FindWay Digital," your trusted partner in digital
              marketing. Our unparalleled strategies transcend conventional
              boundaries, forging seamless connections between consumers and
              brands. Experience heightened visibility and unmatched
              competitiveness in the digital landscape as we elevate your brand
              to new heights with our innovative approach.
            </p>
          </div>
        </div>
      </section>
      {/* ----- */}
      <div className="mt-10 mb-10">
        <div className="w-auto h-auto text-4xl font-RobatoCondesed font-se text-slate-900 ">
          <h1 className="mt-10 text-[32px] uppercase pl-16 max-md:pl-9">
            REASONS TO CHOOSE US OVER
            <span className="font-serif ml-3 mr-3 text-gradient ">
              DIGITAL MARKETING
            </span>
            COMPANIES
          </h1>
        </div>
        <div className="w-full h-auto gap-10 px-16 mt-10  max-md:flex-col max-md:px-6">
          <div>
            <ul className="text-justify font-RobatoCondesed">
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-3 ml-2 text-lg font-normal">
                  FindWay Digital shows success in social media marketing, local
                  SEO, and SEO.
                </span>
              </li>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  For best outcomes, methods are customized to meet specific
                  business objectives.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  combines social media marketing, local search engine
                  optimization, and SEO perfectly for a complete approach.
                </span>
              </li>
              <li className="flex ">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-2 ml-2 text-lg font-normal">
                  gives priority to visible results in search results, local
                  contact, and social media interaction.
                </span>
              </li>
            </ul>
          </div>
          <div>
            <div>
              <ul className="text-text-justify  font-RobatoCondesed">
                <li className="flex ">
                  <img className="h-6 mt-1" src={Tick} alt="loading" />
                  <span className="pb-2 ml-2 text-lg font-normal">
                    gives priority to visible results in search results, local
                    contact, and social media interaction.
                  </span>
                </li>
                <li className="flex ">
                  <img className="h-6 mt-1" src={Tick} alt="loading" />
                  <span className="pb-2 ml-2 text-lg font-normal">
                    carries out extensive evaluations of the audience, industry,
                    and company to support data-driven initiatives.
                  </span>
                </li>

                <li className="flex ">
                  <img className="h-6 mt-1" src={Tick} alt="loading" />
                  <span className="pb-2 ml-2 text-lg font-normal">
                    provide frequent updates and reports that offer clear
                    insights into the success of marketing.
                  </span>
                </li>
                <li className="flex ">
                  <img className="h-6 mt-1" src={Tick} alt="loading" />
                  <span className="pb-2 ml-2 text-lg font-normal">
                    FindWay Digital shows proficiency in the field of digital
                    marketing and has been acknowledged on Search Engine Land
                    and other important websites.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* ----- */}
      <section className="px-16 mt-10 mb-10 max-md:px-6">
        <div>
          <p className="text-center text-[32px]  font-RobatoCondesed text-gradient ">
            FAQS
          </p>
          <h2 className="mt-5 text-center   font-RobatoCondesed uppercase text-[34px]">
            Best Digital Marketing
            <span className="ml-2 font-serif text-gradient  uppercase">
              Company in Madurai
            </span>
            - FAQ's
          </h2>
        </div>
        {/* - */}
        <div className="mt-10">
          <div className="space-y-4  font-RobatoCondesed">
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900 ">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                What services does your digital marketing company offer?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                We specialize in a range of services, including SEO, social
                media marketing, content creation, PPC advertising, and more.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                How can digital marketing benefit my business?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Digital marketing can enhance your online visibility, engage
                your target audience, increase brand awareness, and drive
                conversions, ultimately boosting business growth.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                What is the pricing structure for your digital marketing
                services?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Our pricing is tailored to the specific services your business
                requires. We offer transparent pricing structures with flexible
                options to accommodate different budgets and needs.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                How can I get started with your digital marketing services?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Simply reach out to our team through our website or contact
                information, and we'll schedule a consultation to discuss your
                business goals and determine the best digital marketing strategy
                for your needs.
              </p>
            </details>
          </div>
        </div>
      </section>
    </>
  );
};

export default Digitalmarketing;
