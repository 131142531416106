import React from "react";
import { Link } from "react-router-dom";
import OnpageImage from "../../images/digitalmarketing/180-1808289_for-on-page-optimization-page-seo-ranking-factors-removebg-preview (1).png";
import Tick from "../../images/checkbox.png";
import Search from "../../images/digitalmarketing/Search.png";
import Alt from "../../images/digitalmarketing/Alt 2.png";
import OnpageImageTwo from "../../images/digitalmarketing/Daco_4715056.png";
import OnpageImageThree from "../../images/digitalmarketing/SEOimagethree.jpg";
import { Helmet } from "react-helmet";
const Onpageseo = () => {
  return (
    <div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Expert On-Page SEO Services in Coimbatore</title>

          <meta name="description" content=": Maximize your online presence through meticulous On-Page SEO techniques with Honey Iconics. Our experts fine-tune your website, focusing on content, keywords, and user experience. Enhance search engine visibility and user engagement for sustainable digital success. Trust us to elevate your on-page optimization game. " />
          <link
            rel="canonical"
            href="
            https://honey-iconicss.com/on-page-seo/
            "
          />
        </Helmet>
      </div>
      <div className="h-auto pb-36 sm:pb-96 relative bg-slate-900 ">
        <div className="flex w-auto h-auto auto max-md:flex-col ">
          <div className="lg:w-[50%] h-auto md:auto">
            <h1 className="pl-10 pt-10 pb-2 text-white  font-RobatoCondesed text-[35px] font-semibold ">
              ON <span className="text-gradient "> PAGE </span> SEO
            </h1>
            <div className="pb-5">
              <ul className="flex h-8 pl-10 space-x-2 text-white">
                <li className="flex items-center text-[12px] ">
                  <Link to={"/"}> HOME </Link>
                  <span className="ml-2 text-gray-400 text-[12px]">/</span>
                </li>
                <li className="flex items-center text-[12px] ">ON Page Seo</li>
              </ul>
            </div>
          </div>

          <div className="w-full h-auto pb-2 pt-11 lg:pl-10 lg:pr-28 max-md:px-3 max-md:pt-3 md:px-3">
            <div className="flex lg:w-[100%] h-auto rounded-full shadow-md lg:p-2 md:p-2 bg-slate-200 shadow-text-gradient max-md:p-2">
              <div className="h-auto p-2 font-RobatoCondesed text-white bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full max-md:w-[45%] lg:w-[25%] max-sm:text-[11px] text-center font-se md:w-[30%]">
                ON Page Seo
              </div>
              <div className="overflow-hidden">
                <div className="pt-2 pl-2 font-medium font-RobatoCondesed max-sm:pt-1 marquee">
                  <p>
                    The Best SEO Agency in India which is located in coimbatore
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto px-6">
          <h1 className="text-center  font-RobatoCondesed uppercase text-[35px] font-se text-white">
            What Is
            <span className="ml-3 mr-3 font-serif text-gradient ">
              On Page
            </span>
            SEO ?
          </h1>
        </div>
        <div className="flex  font-RobatoCondesed gap-3 px-10 max-sm:px-6 max-md:flex-col max-lg:flex-col">
          <div className="w-[50%] max-lg:w-full h-auto pt-11 text-[18px] leading-9 text-gray-400 max-md:w-full text-justify">
            On-page SEO is all about optimizing a website’s pages to make them
            more search engine-friendly for the targeted keywords and audience
            and enhance their ranking in search engine results pages (SERPs).
            Essentially, it’s about making your website as effortless to find
            and navigate as possible. To achieve this, there are many techniques
            that businesses and website owners can use, such as creating
            high-quality content that’s relevant to their audience, using
            relevant keywords throughout their content and meta tags, and making
            sure their website is visually adorable and easy to navigate.
          </div>

          <div className="w-[50%] h-96 max-md:w-full max-sm:h-auto mx-lg:h-auto max-lg:w-auto ">
            <center>
              <img
                className="object-cover h-auto py-3 w-au max-md:object-cover "
                src={OnpageImage}
                alt="loading"
              />
            </center>
          </div>
        </div>
        <p className="px-10  font-RobatoCondesed text-[18px] leading-8  text-gray-400 text-justify">
          One of the key things to remember about On-Page SEO is that it’s all
          about providing a great user experience. That means making sure your
          website is easy to use, loads quickly, and has relevant and engaging
          content that keeps visitors coming back for more. It also means making
          sure your website is mobile-friendly, as more and more people are
          accessing the internet on their mobile devices these days.
        </p>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="absolute -bottom-2"
        >
          <path
            fill="white"
            fill-opacity="1"
            d="M0,32L30,48C60,64,120,96,180,138.7C240,181,300,235,360,266.7C420,299,480,309,540,266.7C600,224,660,128,720,122.7C780,117,840,203,900,197.3C960,192,1020,96,1080,74.7C1140,53,1200,107,1260,112C1320,117,1380,75,1410,53.3L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
          ></path>
        </svg>
      </div>
      {/* ========== section - Two ========== */}
      <section>
        <div>
          <div className="w-full h-auto px-10 max-md:px-6 ">
            <h1 className="mt-10 text-[35px] font-RobatoCondesed uppercase font-normal max-md:text-[32px]  ">
              What Is Included In
              <span className="ml-3 mr-3 font-serif text-gradient">
                On-Page SEO
              </span>
              Services?
            </h1>
            <p className="pr-5 font-RobatoCondesed  mt-5 text-justify text-black leading-loose text-[18px] max-md:pr-0">
              Some of the most important On-Page SEO factors include using
              descriptive and keyword-rich titles and meta descriptions,
              incorporating header tag text-justify to structure your content,
              and optimizing your images and videos with descriptive alt tags.
              By accomplishing all of these things, businesses and website
              owners can improve their website’s online visibility and attract
              more organic traffic, ultimately increasing their chances of
              ranking higher in Search Engine Results Pages (SERPs).
            </p>

            <div className="mt-16 mb-10">
              <p className="mt-5 font-RobatoCondesed text-[35px] uppercase">
                Our On-Page
                <span className="ml-3 mr-3 font-serif text-gradient">
                  SEO Services
                </span>
                Include: -
              </p>
              <div className="grid font-RobatoCondesed grid-cols-3 gap-16 mt-10 text-justify max-md:grid-cols-1 ">
                <div className="">
                  <ul className="">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] text-black">
                        Higher-Intent Keyword Research That Has Less Competition
                        and High Search Volume
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] text-black">
                        Meta tags for better CTR(Click-through rate)
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] text-black">
                        Sitemap Creation for Users and Search Engines
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] text-black">
                        Tracking Setup (Analytics and Search Console)
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="">
                  <div>
                    <ul className="">
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Internal Linking to Improve Website Structure and
                          Faster Crawling
                        </span>
                      </li>

                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Heading Tag Optimization
                        </span>
                      </li>

                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Alt Text Optimization
                        </span>
                      </li>
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Technical Analysis of the Website (UI, UX, CTA, Bounce
                          Rate)
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="">
                  <div>
                    <ul className="">
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Robots File Set Up and Optimization
                        </span>
                      </li>
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Anchor Text Optimization
                        </span>
                      </li>

                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Content Optimization
                        </span>
                      </li>
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Blog Audit
                        </span>
                      </li>
                      <li className="flex ">
                        <img className="h-6 mt-1" src={Tick} alt="loading" />
                        <span className="pb-5 ml-2 text-[18px] text-black">
                          Tracking Set Up (Analytics and Search Console)
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== section - three ========== */}
      <section className="py-5 mt-20 sec-four">
        <div className="px-10 max-md:px-6 ">
          <h1 className="mt-10 text-[35px] uppercase font-RobatoCondesed  font-normal  text-center">
            <span className="ml-3 mr-3 font-serif text-gradient ">
              Overview
            </span>
            of On-Page SEO Services
          </h1>
        </div>
        <div className="flex px-10 max-md:flex-col max-sm:items-center max-md:px-2">
          <div className="w-[50%] h-auto  max-md:w-full">
            <img src={Search} alt="loading" />
          </div>
          <div className="w-[50%] h-auto  p-3 max-md:w-full">
            <h1 className="w-full h-auto font-RobatoCondesed uppercase  text-[35px] mt-10 text-justify max-sm:text-[32px] max-md:text-center">
              <span className="ml-1 font-serif text-gradient "> Keyword</span>{" "}
              Research
            </h1>
            <p className="mt-10 text-[18px] font-RobatoCondesed text-justify">
              Our on-page SEO expertise involves meticulous keyword research,
              content optimization, and strategic internal linking to boost your
              website's visibility. With a focus on responsive design and
              user-friendly navigation, we ensure a seamless experience for your
              audience. Our commitment to optimizing page loading speed,
              implementing schema markup, and integrating social media elements
              guarantees a well-rounded approach to enhancing your online
              presence. Partner with us for a finely-tuned, user-focused website
              that's primed for success in search rankings.
            </p>
            <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5 w-auto text-3xl mt-20 max-sm:w-[50%] ">
              <span className="font-semibold">Contact Us </span>
            </button>
          </div>
        </div>
      </section>
      {/* ========== section - Four ========== */}
      <section className="mt-28 ">
        <div>
          <h1 className="mt-10 text-[35px] uppercase font-RobatoCondesed   text-center max-sm:text-[32px] px-10">
            The Value Of
            <span className="ml-3 mr-3 font-serif text-gradient">
              Researching Keywords
            </span>
            For SEO
          </h1>
        </div>

        <div className="px-10 max-md:px-6">
          <h1 className="mt-8 text-[25px]  uppercase  font-RobatoCondesed text-justify">
            Keyword
            <span className="ml-2 mr-2 font-serif text-gradient">
              Research Is Crucial
            </span>{" "}
            In SEO Because
          </h1>
          <div>
            <div className="flex mt-5 font-RobatoCondesed max-md:flex-col">
              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5  ml-2 mt-1 text-[18px] text-black">
                  Strategic keyword research ensures content resonates with user
                  intent, targeting the most pertinent audience.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Efficient use of keywords enhances visibility, contributing to
                  higher search engine rankings and increased organic traffic
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Insightful keyword research facilitates the strategic
                  placement of terms, optimizing content for search engines, and
                  user engagement.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Understanding competitors' keyword strategies empowers
                  informed decisions, enabling the development of unique and
                  effective SEO approaches.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== section - Five ========== */}
      <section>
        <div className="mx-10 mt-28 max-md:mx-6">
          <h1 className="mt-10 text-[35px] uppercase font-normal  max-sm:text-[32px] max-sm:text-center">
            The following procedures are
            <span className="ml-3 mr-3 font-serif text-gradient">
              involved in keyword research
            </span>
          </h1>
        </div>

        <div className="px-10 max-md:px-6">
          <h1 className="mt-8 text-[20px] italic  font-serif">
            Keyword research involves the following steps
          </h1>
          <div>
            <div className="flex font-RobatoCondesed gap-10 mt-10 justify-evenly max-md:flex-col">
              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Clearly outline the purpose and objectives of the keyword
                  research process.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Generate an initial list of topics related to your business,
                  products, or services.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Use tools and analytics to identify related terms, synonyms,
                  and long-tail keywords.{" "}
                </span>
              </div>
            </div>

            <div className="font-RobatoCondesed flex gap-10 mt-10 justify-evenly max-md:flex-col">
              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Analyze competitors to uncover successful keyword strategies
                  and potential opportunities.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  To rank each term based on importance and reach, consider its
                  search activity and competition.
                </span>
              </div>

              <div className="flex">
                <img className="h-6 mt-1" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 mt-1 text-[18px] text-black">
                  Narrow down the list to the most relevant and effective
                  keywords, considering user intent and business goals.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== section - Six ========== */}
      <section>
        <div className="mx-10 mt-28 max-md:mx-6">
          <h1 className="mt-10 text-[35px] uppercase font-RobatoCondesed  font-normal text-center max-sm:text-[32px] max-sm:text-center">
            Research Tools Used for Keywords in
            <span className="ml-3 mr-3 font-serif text-gradient">
              SEO keyword research tool
            </span>
            In SEO
          </h1>
        </div>

        <div className="px-10 max-md:px-5">
          <h1 className="mt-8 text-[20px] font-serif  text-center italic">
            Keyword research involves the following steps
          </h1>
          <section className="py-6 dark:bg-gray-800">
            <div className="container flex flex-col justify-center p-4 mx-auto">
              <div className="grid font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    Google Keyword Planner
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    Ubersuggest
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    KeywordTool.io
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    Semrush
                  </span>
                </div>
                {/* ====== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    Ahrefs
                  </span>
                </div>

                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    Moz Keyword Explorer
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    SEMrush
                  </span>
                </div>
                {/* ===== */}
                <div className="flex w-auto h-auto p-5 bg-white rounded-full shadow-lg shadow-gray-500">
                  <img className="h-6 mt-2" src={Tick} alt="loading" />
                  <span className=" ml-2 mt-1 text-[18px] text-black">
                    KeywordTool.io
                  </span>
                </div>
                {/* ===== */}
              </div>
            </div>
          </section>

          <center>
            {" "}
            <p className="text-center font-RobatoCondesed text-[22px] mt-5 text-gray-700">
              These tools help in identifying relevant keywords, analyzing their
              search volume and competition level, and providing insights into
              the competition’s strategy.
            </p>{" "}
          </center>
        </div>
      </section>

      {/* ========== section - Six - Meta Tag ========== */}
      <section className="mt-20 ">
        <div className="py-16 sec-four ">
          <div className="px-16 max-md:px-6">
            <h1 className="text-center font-RobatoCondesed uppercase text-[35px]">
              <span className="mr-2 font-serif text-gradient">Meta Tag</span>
              Optimization
            </h1>

            <div className="flex max-md:flex-col">
              <div className="w-[50%]  h-auto max-md:w-auto">
                <p className="text-[20px] font-RobatoCondesed pt-10 text-justify max-md:text-[22px] text-black leading-loose">
                  Meta tags are snippets of code that are placed in the head
                  section of a web page’s HTML code. They provide information
                  about the web page to search engines and website visitors.
                </p>
                <p className="text-[22px] font-RobatoCondesed pt-10 text-justify max-md:text-[22px] text-black leading-loose">
                  Meta tags do not appear on the web page itself but are visible
                  to search engines and are used to provide information about
                  the page’s content.
                </p>
                <button class="btn-hover color-2  pt-1 pb-1 pl-10 pr-10 w-auto text-3xl mt-28 max-sm:w-[50%] ">
                  <span className="font-semibold">Contact Us </span>
                </button>
              </div>
              <div className="w-[50%] h-auto max-md:w-full">
                <img src={Search} alt="loading" />
              </div>
            </div>
          </div>
        </div>

        <div className="mx-16 mt-20 max-md:mx-6">
          <h1 className=" uppercase font-RobatoCondesed text-[35px]">
            Importance Of
            <span className="font-serif text-gradient"> Meta Tags</span>
          </h1>

          <p className="text-justify text-[18px] font-RobatoCondesed mt-5 ">
            Meta tags are important for SEO because they help search engines
            understand the content of a web page. They provide information such
            as the page title, description, and keywords, which help search
            engines and users understand what the page is about. Properly
            optimized meta tags can help improve a web page’s visibility in
            search engine results pages (SERPs) and attract more traffic to the
            website.
          </p>

          <div className="mt-20">
            <div>
              <h1 className="text-[35px] font-RobatoCondesed uppercase">
                How Do We Write
                <span className="ml-3 mr-3 font-serif text-gradient">
                  SEO-Friendly Meta Tags
                </span>
              </h1>
            </div>
            <p className="mt-5 text-[18px] font-serif italic">
              To write SEO-friendly meta tags, follow these tips:
            </p>
            <div className="mt-10 ">
              <div>
                <ul className="font-RobatoCondesed">
                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5  ml-2 text-[18px] leading-loose">
                      Write original, short titles (less than 60 characters)
                      that feature the main keyword for proper page
                      representation.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 text-[18px] leading-loose">
                      Write engaging meta descriptions (less than 155
                      characters) that entice viewers to click through by
                      describing the content and mentioning the main keyword.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 text-[18px] leading-loose">
                      Make sure the title and description meta tags are
                      naturally aligned with the content of the page by
                      including appropriate keywords.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 text-[18px] leading-loose">
                      Emphasize the value of unique, clear titles (less than 60
                      characters) for every page to improve search engine
                      understanding.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 text-[18px] leading-loose">
                      In order to facilitate unique page identification and
                      higher search engine results, highlight the need to avoid
                      using duplicate meta tags.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 text-[18px] leading-loose">
                      Promote the benefits of using schema markup to give search
                      engines extra information, like product ratings, costs,
                      and reviews.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== section - Seven - ========== */}

      <section>
        <div className="px-16 max-md:px-6 font-RobatoCondesed">
          <div>
            <h1 className="text-[32px] text-gradient  mt-10 ">Sitemap</h1>
            <div className="mt-10 text-justify">
              <p className="text-[18px] leading-loose">
                A sitemap is a file that provides a list of all the pages on a
                website, including the URLs and metadata for each page. The
                purpose of a sitemap is to help search engines crawl and index
                all the pages on a website more efficiently.
              </p>
              <p className="text-[18px] leading-loose mt-10">
                Sitemaps can be created in various formats, such as XML, HTML,
                or TXT, and can be submitted to search engines to inform them
                about the website’s structure and content. This helps search
                engines understand the website’s organization and hierarchy,
                which can help improve the website’s search engine ranking.
              </p>
              <p className="text-[18px] leading-loose mt-10">
                Sitemaps can also include additional information about each
                page, such as the date it was last modified, the frequency of
                updates, and the importance of the page relative to other pages
                on the site. This metadata can help search engines determine
                which pages to crawl and how often to revisit them.
              </p>
              <p className="text-[18px] leading-loose mt-10">
                In addition to helping search engines, HTML sitemaps can also be
                useful for website visitors, as they provide a quick and easy
                way to navigate to different pages on the site. Some websites
                may include a link to their sitemap in the footer or navigation
                menu to make it easily accessible to users.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ========== section - eight - ========== */}
      <section>
        <div className="px-16 py-10 mt-20 bg-gray-100 max-md:px-6">
          <div>
            <h1 className="text-center uppercase font-RobatoCondesed text-[35px] ">
              <span className="ml-3 mr-3 font-serif text-gradient">
                Optimizing
              </span>
              Heading Tags
            </h1>
            <p className="mt-10 text-[18px] font-RobatoCondesed leading-loose text-center">
              Heading tags (H1, H2, H3, and so on) are important parts of SEO
              on-page optimization. They can enhance the user experience by
              making content easier to read and navigate, and they aid search
              engines in understanding the hierarchy and structure of content on
              a webpage.
            </p>

            <div className="mt-20">
              <h1 className="text-[35px] font-RobatoCondesed uppercase text-center">
                This Is
                <span className="ml-3 mr-3 font-serif text-gradient">
                  How We Improve The Headings
                </span>
                On Your Website For Better SEO
              </h1>

              <div className="mt-10 ">
                <div>
                  <ul className="">
                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] font-RobatoCondesed  leading-loose">
                        <span className="font-serif uppercase text-[20px] mr-2 text-gradient">
                          Use H1 tag for main headings :
                        </span>
                        The H1 tag should be used for the main heading of the
                        webpage and should include the primary keyword that the
                        webpage is targeting. This helps search engines
                        understand the topic of the page and can improve its
                        relevance and ranking.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                        <span className="font-serif mr-2 text-[20px] uppercase text-gradient">
                          Use H2-H6 tags for subheadings :
                        </span>
                        Use the H2 tag for the first subheading, and subsequent
                        subheadings should use H3, H4, and so on, in descending
                        order of importance. This creates a clear hierarchy of
                        the content and helps search engines understand the
                        relationships between different sections of the content.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                        <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                          Keep headings concise and descriptive :
                        </span>
                        Headings should be concise and accurately describe the
                        content that follows. They should be relevant to the
                        topic of the page and should include important keywords
                        when appropriate. Avoid using generic or vague headings
                        that don’t accurately describe the content.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] font-RobatoCondesed leading-loose">
                        <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                          Use CSS styling instead of font size for headings :
                        </span>
                        Instead of using larger font sizes to indicate headings,
                        use CSS styling to style the heading tags. This helps
                        maintain consistency in the appearance of the headings
                        and avoids potential issues with accessibility and
                        mobile responsiveness.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                        <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                          Avoid overusing headings :
                        </span>
                        While headings can improve the readability and SEO of a
                        webpage, overusing them can be counterproductive. Use
                        headings only when appropriate, and avoid using multiple
                        H1 tags on a single webpage.
                      </span>
                    </li>

                    <li>
                      <p className="text-[18px] font-RobatoCondesed">
                        Overall, optimizing a robots.txt file can help improve a
                        website’s SEO by preventing search engines from crawling
                        unimportant or sensitive pages while allowing access to
                        important pages.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== section - nine - ========== */}
      <section>
        <div className="px-16 mt-20 max-sm:px-6">
          <h1 className="text-[35px] uppercase font-RobatoCondesed text-center">
            Alt Text
            <span className="ml-3 mr-3  font-serif text-gradient">
              Optimization
            </span>
          </h1>
          <div className="flex gap-5 max-md:flex-col">
            <div className="w-[50%] pt-10 max-md:w-full ">
              <div className="font-RobatoCondesed">
                {" "}
                <p className="text-[18px] leading-loose text-justify">
                  Alt text (alternative text) is a descriptive text that is
                  added to an image on a webpage to describe its content.
                </p>
                <p className="mt-5 leading-loose text-[18px] text-justify">
                  It is an important element of on-page optimization in SEO as
                  it helps search engines understand the content of the image
                  and can improve the accessibility of the webpage for visually
                  impaired users.
                </p>{" "}
              </div>

              <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5 w-auto text-3xl mt-10 max-sm:w-[50%] ">
                <span className="font-semibold">Contact Us </span>
              </button>
            </div>
            <div className="w-[50%] pl-10 max-md:w-full">
              <img className="object-cover h-96" src={Alt} alt="loading" />
            </div>
          </div>
        </div>

        <div className="px-16 mt-16 max-md:px-6">
          <h1 className="text-[35px] uppercase font-RobatoCondesed max-sm:text-[35px] text-center">
            This Is How Your Images
            <span className="ml-3 mr-3 font-serif text-gradient">
              Are Optimized For Better SEO
            </span>
          </h1>
        </div>

        <div className="px-16 mt-10 max-md:px-6">
          <div>
            <ul className="">
              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                  <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                    Use descriptive text :
                  </span>
                  The alt text should accurately describe the content of the
                  image. Use clear and concise language to describe the image,
                  including any relevant keywords that are related to the
                  content of the webpage.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                  <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                    Keep it concise :
                  </span>
                  The alt text should be concise and to the point. Avoid using
                  long or overly descriptive sentences that may not accurately
                  describe the content of the image.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 text-[18px] font-RobatoCondesed leading-loose">
                  <span className="font-serif  mr-2 uppercase text-[20px] text-gradient">
                    Use relevant keywords :
                  </span>
                  Incorporate relevant keywords into the alt text when
                  appropriate, but avoid keyword stuffing. Only use keywords
                  that accurately describe the content of the image.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                  <span className="font-serif  mr-2 uppercase text-[20px] text-gradient">
                    Be mindful of context
                  </span>
                  The alt text should be contextually relevant to the content of
                  the webpage. Avoid using generic or irrelevant text that does
                  not accurately describe the content of the image.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                  <span className="font-serif mr-2 uppercase text-[20px] text-gradient">
                    Don’t leave it blank :
                  </span>
                  Always include alt text for every image on the webpage, even
                  if it is a decorative image or a spacer. Leaving the alt text
                  blank can negatively impact the accessibility of the webpage.
                </span>
              </li>

              <li className="flex ">
                <img className="h-6 mt-3" src={Tick} alt="loading" />
                <span className="pb-5 ml-2 font-RobatoCondesed mr-2 text-[18px] leading-loose">
                  <span className="font-serif  mr-2 uppercase text-[20px] text-gradient">
                    Use proper formatting :
                  </span>
                  Use proper formatting, such as capitalization and punctuation,
                  to make the alt text easier to read and understand.
                </span>
              </li>

              <li>
                <p className="mt-10 text-[18px] font-RobatoCondesed">
                  In summary, optimizing alt text can improve the SEO and
                  accessibility of a webpage. Use descriptive and concise
                  language, include relevant keywords, be mindful of context,
                  never leave it blank, and use proper formatting.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* ========== section - ten - ========== */}
      <section>
        <div className="mt-20">
          <div className="w-full h-auto px-16 py-16 bg-gray-100 max-md:px-6">
            <h2 className="text-[35px] uppercase font-RobatoCondesed">
              Content{" "}
              <span className="font-serif  mr-2   text-gradient">
                {" "}
                Optimization
              </span>{" "}
            </h2>
            <p className="mt-2 text-[20px] font-serif italic">
              Content optimization is the process of optimizing website content
              to improve its search engine visibility and attract more traffic.
            </p>

            <h2 className="text-[35px] mt-10 uppercase font-RobatoCondesed">
              What do we do to{" "}
              <span className="font-serif  mr-2   text-gradient">
                {" "}
                optimize your website’s content
              </span>{" "}
              for better SEO?
            </h2>

            <div className="mt-10 ">
              <div>
                <ul className="">
                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5  font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Use H1 tag for main headings :
                      </span>
                      The H1 tag should be used for the main heading of the
                      webpage and should include the primary keyword that the
                      webpage is targeting. This helps search engines understand
                      the topic of the page and can improve its relevance and
                      ranking.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 font-RobatoCondesed  text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Use H2-H6 tags for subheadings :
                      </span>
                      Use the H2 tag for the first subheading, and subsequent
                      subheadings should use H3, H4, and so on, in descending
                      order of importance. This creates a clear hierarchy of the
                      content and helps search engines understand the
                      relationships between different sections of the content.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Keep headings concise and descriptive :
                      </span>
                      Headings should be concise and accurately describe the
                      content that follows. They should be relevant to the topic
                      of the page and should include important keywords when
                      appropriate. Avoid using generic or vague headings that
                      don’t accurately describe the content.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed  ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Use CSS styling instead of font size for headings :
                      </span>
                      Instead of using larger font sizes to indicate headings,
                      use CSS styling to style the heading tags. This helps
                      maintain consistency in the appearance of the headings and
                      avoids potential issues with accessibility and mobile
                      responsiveness.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 ml-2 font-RobatoCondesed text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Avoid overusing headings :
                      </span>
                      While headings can improve the readability and SEO of a
                      webpage, overusing them can be counterproductive. Use
                      headings only when appropriate, and avoid using multiple
                      H1 tags on a single webpage.
                    </span>
                  </li>

                  <li>
                    <p className="font-RobatoCondesed text-[18px] text-center max-sm:text-justify">
                      Overall, optimizing a robots.txt file can help improve a
                      website’s SEO by preventing search engines from crawling
                      unimportant or sensitive pages while allowing access to
                      important pages.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== section - Eleven - ========== */}

      <section>
        <div className="px-16 max-md:px-6">
          <div>
            <h1 className="text-center uppercase text-[35px] mt-16">
              Internal
              <span className="ml-3 mr-3 font-serif text-gradient">
                Linking Optimization
              </span>
            </h1>
            <p className="text-center font-RobatoCondesed text-[20px] mt-10">
              The act of connecting pages on the same website is known as
              internal linking. SEO values internal linking for a number of
              reasons.
            </p>

            <p className="text-center font-RobatoCondesed text-[20px] mt-10">
              Internal linking aids in the crawling and indexing of pages by
              search engines, giving them a better understanding of the
              hierarchy and structure of a website. Search engines find new
              pages and material by following links on a webpage leading to
              other pages, which they do while analyzing the page.
            </p>

            <div className="mt-10 ">
              <div>
                <ul className="">
                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Improves website navigation and user experience :
                      </span>
                      internal linking helps users navigate a website and find
                      relevant content easily. By providing links to related
                      content within a website, users are more likely to stay on
                      the website for longer and engage with more content,
                      improving the overall user experience.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Distributes link equity :
                      </span>
                      Link equity refers to the value passed from one webpage to
                      another through links. Internal linking can help
                      distribute link equity throughout a website, improving the
                      ranking potential of individual pages and improving the
                      overall domain authority of the website.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Enhances keyword relevancy :
                      </span>
                      Internal linking helps establish keyword relevancy by
                      linking pages together that are thematically related. By
                      linking to pages with relevant keywords, search engines
                      can better understand the topics and keywords associated
                      with a website.
                    </span>
                  </li>

                  <li className="flex ">
                    <img className="h-6 mt-3" src={Tick} alt="loading" />
                    <span className="pb-5 font-RobatoCondesed ml-2 text-[18px] leading-loose">
                      <span className="font-serif uppercase mr-2 text-[20px] text-gradient">
                        Increases page authority :
                      </span>
                      Internal linking can help increase the authority of
                      individual pages by passing link equity from
                      high-authority pages to lower-authority pages. By linking
                      to high-authority pages, lower-authority pages can benefit
                      from their authority and improve their own ranking
                      potential.
                    </span>
                  </li>

                  <li>
                    <p className="leading-loose font-RobatoCondesed text-[18px] mt-5 text-center ">
                      Internal linking is important in SEO for improving website
                      architecture and navigation and the user experience,
                      distributing link equity, enhancing keyword relevancy, and
                      increasing page authority. By linking pages within a
                      website, you can improve the search engine visibility and
                      ranking potential of individual pages and the overall
                      domain authority of the website.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== section - Twelve - ========== */}

      <section>
        <div className="px-16 py-16 mt-20 bg-slate-900 max-md:px-6">
          <div>
            <h1 className="text-[35px] uppercase text-center text-white">
              How Do We
              <span className="ml-2 mr-3 font-serif text-gradient">
                Optimize Websites
              </span>
              For Better On-Page SEO Scores?
            </h1>

            <p className="mt-10 font-RobatoCondesed leading-loose text-center text-[18px] text-white">
              To optimize the website for a better On-Page SEO score, it’s
              important to centralize the audience’s needs and interests. We
              start by performing keyword research to choose our website’s most
              relevant and valuable keywords. It helps us generate content that
              reverberates with our target audience and improves the likelihood
              that they’ll find and engage with your website.
            </p>

            <div className="flex max-md:flex-col">
              <div className=" w-[50%] max-md:w-full">
                <p className="mt-10 font-RobatoCondesed  leading-loose text-justify text-white text-[18px]">
                  Once we’ve specified the keywords, it’s time to optimize the
                  website’s meta tags and content. We use title tags and meta
                  descriptions to convey what the website is about and what
                  users can expect to find there. We make sure content is
                  high-quality, engaging, and optimized for keywords by using
                  headings, including alt text for images, and formatting your
                  content for readability.
                </p>

                <p className="mt-10 font-RobatoCondesed  leading-loose text-justify text-white text-[18px]">
                  Technical SEO factors are also critical for better On-Page
                  SEO. We optimize a website’s page speed by compressing images,
                  minifying code, and enabling caching to ensure a fast and
                  responsive experience for your users. We make sure the website
                  is mobile-friendly and easy to navigate, especially since more
                  and more people are accessing websites from their mobile
                  devices. You must check out our technical SEO services.
                </p>
              </div>

              <div className="w-[50%] max-md:w-full">
                <center>
                  {" "}
                  <img
                    className="object-contain w-auto h-96 "
                    src={OnpageImageTwo}
                    alt="loading"
                  />{" "}
                </center>
              </div>
            </div>

            <p className="mt-10 font-RobatoCondesed leading-loose text-justify text-white text-[18px]">
              Finally, we track and monitor website performance using tools like
              Google Analytics and Search Console. This will help us identify
              areas for progress and make data-driven decisions to continually
              optimize the website for a better On-Page SEO score.
            </p>

            <p className="mt-10 font-RobatoCondesed leading-loose text-justify text-white text-[18px]">
              Overall, optimizing a website for a better On-Page SEO score needs
              a user-focused strategy that considers the audience’s needs and
              preferences. By using relevant keywords, developing high-quality
              content, optimizing technical factors, and following your
              website’s performance, we can attract more visitors and improve
              your website’s search engine rankings.
            </p>
          </div>
        </div>
      </section>

      {/* ========== section - thirtheen - ========== */}
      <section>
        <div className="mt-16 ">
          <div>
            <h1 className="text-[35px] uppercase text-center px-6">
              Why
              <span className="font-serif text-gradient"> On-Page SEO </span>
              Is Important?
            </h1>

            <div className="px-16 font-RobatoCondesed max-md:px-6">
              <p className="mt-5 leading-loose text-justify text-[18px]">
                Overall, optimizing a website for a better on-page SEO score
                needs a user-focused strategy that considers the audience’s
                needs and preferences. By using relevant keywords, developing
                high-quality content, optimizing technical factors, and
                following your website’s performance, we can attract more
                visitors and improve your website’s search engine rankings.
              </p>
              <div className="flex max-md:flex-col">
                <div className=" w-[50%] max-md:w-full">
                  <p className="mt-10 leading-loose text-justify text-[18px]">
                    Once we’ve specified the keywords, it’s time to optimize the
                    website’s meta tags and content. We use title tags and meta
                    descriptions to convey what the website is about and what
                    users can expect to find there. We make sure content is
                    high-quality, engaging, and optimized for keywords by using
                    headings, including alt text for images, and formatting your
                    content for readability.
                  </p>
                  <p className="mt-10 leading-loose text-justify text-[18px]">
                    Another significant benefit of on-page SEO is that it can
                    help businesses compete with larger corporations in highly
                    competitive markets.
                  </p>
                  <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5 w-72 text-3xl mt-10 max-sm:w-[50%] ">
                    <span className="font-semibold">Contact Us </span>
                  </button>
                </div>

                <div className="w-[50%]  max-md:w-full">
                  <img
                    className="object-contain w-[100%] h-auto p-5 "
                    src={OnpageImageThree}
                    alt="loading"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========== section - Fourtheen - ========== */}
      <section>
        <div className="px-16 py-16 mt-20 bg-gray-50 max-md:px-6">
          <div>
            <h1 className="text-[35px] uppercase">
              Why
              <span className="font-serif mr-2 ml-2 text-gradient">
                Honey Iconics{" "}
              </span>
              For Best On-Page SEO Services?
            </h1>

            <p className="text-[18px] leading-loose mt-5 font-RobatoCondesed">
              If you’re looking for the best on-page SEO services, then Honey
              Iconics is the place to be. Many businesses are struggling with
              their online visibility due to a lack of appropriate optimization
              techniques. However, with our team of experts, you can rest
              assured that your website will rank higher in search engines.
            </p>

            <p className="text-[18px] leading-loose mt-8  font-RobatoCondesed">
              At Honey Iconics, we understand the significance of on-page SEO
              for enhancing website performance. We use cutting-edge tools and
              techniques to analyze every aspect of your website, including its
              structure, content, meta tags, titles, headings, images, and more.
              Based on their analysis, they create a customized plan that
              addresses all areas of improvement required for ranking higher in
              search engines.
            </p>

            <p className="text-[18px] font-RobatoCondesed leading-loose mt-8">
              If you’re looking for the best on-page SEO services, then Honey
              Iconics is the place to be. Many businesses are struggling with
              their online visibility due to a lack of appropriate optimization
              techniques. However, with our team of experts, you can rest
              assured that your website will rank higher in search engines.
            </p>

            <p className="text-[18px]  font-RobatoCondesed leading-loose mt-8">
              With Honey Iconics as your partner for on-page SEO optimization
              services in India, you can expect world-class on-page SEO services
              with 100% transparency throughout the process.
            </p>

            <p className="text-[18px]  font-RobatoCondesed leading-loose mt-8">
              Our comprehensive SEO services include optimizing your website’s
              content, meta descriptions, tags, and internal linking structures.
              All these factors play a crucial role in specifying your search
              engine ranking position (SERP).
            </p>

            <p className="text-[18px]  font-RobatoCondesed leading-loose mt-8">
              Our on-page SEO experts use only the most delinquent and
              innovative approaches to ensure that our clients accomplish their
              expected results. We work closely with our clients to understand
              their unique needs and objectives before developing an effective
              strategy that delivers results.
            </p>

            <p className="text-[18px]  font-RobatoCondesed leading-loose mt-8">
              We offer the best SEO services at very affordable prices. Visit
              our Complete SEO Packages, which include off-page SEO services and
              content marketing services, to provide better results for your SEO
              campaign.
            </p>

            <center>
              <button class="btn-hover color-2  w-[30%] pt-1 pb-1 pl-5 pr-5  text-3xl mt-10 max-sm:w-[50%] ">
                <span className="font-semibold">
                  Visit our SEO packages now!
                </span>
              </button>
            </center>
          </div>
        </div>
      </section>
      {/* ========== section - Fifteen - ========== */}
      <section>
        <div className="px-16 mt-20 max-md:px-6">
          <div>
            <h1 className="text-center font-RobatoCondesed uppercase text-[35px]">
              Here Are Some
              <span className="ml-3 mr-3 font-serif text-gradient">
                Frequently Asked Questions
              </span>
              About On-Page SEO
            </h1>
          </div>
        </div>

        <div className="px-16 mt-10 mb-10 max-md:px-6">
          <div className="space-y-4 font-RobatoCondesed">
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900 ">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                What are the key elements of on-page SEO?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Key elements include optimizing title tags, meta descriptions,
                headers, URL structure, and incorporating relevant keywords.
                Content quality, internal linking, and mobile optimization are
                also crucial.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                How important is keyword optimization in on-page SEO?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Keyword optimization is vital. Select and integrate relevant
                keywords naturally within content and meta tags to align with
                user search queries and improve search engine ranking.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                How does site speed affect on-page SEO?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Site speed is crucial. Faster-loading pages enhance the user
                experience and contribute to higher search rankings, as search
                engines prioritize delivering quick and efficient results.
              </p>
            </details>
            <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
              <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                What are some common on-page SEO mistakes to avoid?
              </summary>
              <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                Common mistakes include keyword stuffing, poor content quality,
                neglecting mobile optimization, and ignoring the user
                experience. Regularly audit and update your on-page SEO strategy
                to avoid these pitfalls.
              </p>
            </details>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Onpageseo;
