import React from "react";
import Imageone from "../images/Software-development/Software-development (2).png";
import Imagetwo from "../images/Software-development/ecommerce.png";
import ImageThree from "../images/Software-development/Crm.png";
import ImageFour from "../images/Software-development/Web-development.png";
import ImageFive from "../images/Software-development/why-software-development.png";
import Tick from "../images/checkbox (1).png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import BannerImage from "../images/Software-development/1358828_10747.jpg";
const SoftwareDevelopment = () => {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Custom Software Development Company in Madurai</title>
          <link
            rel="canonical"
            href="
            https://honey-iconicss.digital-markting/"
            content="At FindWay Digital, we're proud to be one of the top software development firms in Madurai, committed to creating creative, effective digital solutions that are customized to your specific business requirements. Our talented development team is dedicated to providing excellent software that will enable your business to succeed.

            "
          />

          <meta name="description" content="FindWay Digital is a leading custom software development company in Madurai. We offer tailored software solutions to businesses, leveraging the latest technologies for enhanced efficiency and productivity." />
        </Helmet>
      </div>
      <section>
        <section>
          <div>
            <section class="wrapper mb-20 pb-10">
              <div id="stars1"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>

              <div className="flex pt-10 max-lg:flex-col">
                <div className="w-[100%] text-[3.8rem] pt-20 max-lg:w-full md:px-16 max-lg:pl-none max-md:px-6  ">
                  <h1
                    id="title"
                    className="leading-snug font-bold text-center uppercase  max-sm:text-[2rem] text-white "
                  >
                    <span>Software Development Company</span>
                  </h1>
                  <p className="mt-6 md:px-18 max-sm:text-[15px]  text-[18px] font-RobatoCondesed text-center text-white ">
                    Are you searching for the best website development services
                    in Madurai to create a modern website for your business
                    or startup? Take quick action and hire FindWay Digital A
                    leading website development company in India that offers the
                    best website development services at affordable prices.
                  </p>

                  <center>
               <Link to={"/contact"}>  <button class="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us{" "}
                      </span>
                    </button>{" "} </Link>
                  
                  </center>
                </div>
              </div>
            </section>
          </div>
        </section>
      </section>
      {/* ============== Best software development and Maintenance Company in Madurai, India ========== */}
      <section>
        <div className="px-16 font-RobatoCondesed mt-10 max-md:px-6">
          <h2 className="text-[35px] font-normal max-sm:text-[35px]">
            <span className="font-serif uppercase text-gradient ">
              Best software development and Maintenance Company{" "}
            </span>{" "}
            in Madurai.
          </h2>
          <p className="mt-5 text-justify  text-[18px] leading-loose font-normal">
            To get the best software development company in Madurai for your
            requirements, it's critical to know what to search for. Before
            making your choice, you should take a look at a number of things,
            including their tools, experience, and expertise. All software
            maintenance tasks, including installation, troubleshooting,
            upgrades, and problem diagnosis and resolution, are guaranteed to be
            completed by a comprehensive service.
          </p>
        </div>
      </section>
      {/* ============== We are Experienced ========== */}
      <section>
        <div className="px-16 font-RobatoCondesed  mt-10 max-md:px-6">
          <h2 className="text-[35px] font-normal">
            <span className="font-serif text-gradient">
              We are Experienced
            </span>{" "}
          </h2>
          <p className="mt-5 text-justify  text-[18px] leading-loose font-normal">
            When it comes to navigating the dynamic landscape of software
            development services company in Madurai, experience matters. At
            FindWay Digital, we bring a wealth of expertise to the table as a
            distinguished software development company in Madurai. With years
            of honed skills and a proven track record, we stand as your reliable
            partner in digital innovation.
          </p>
        </div>
      </section>
      {/* ==============We are distinctly different ========== */}
      <section>
        <div className="px-16 font-RobatoCondesed mt-10 max-md:px-6">
          <h2 className="text-[35px] font-normal">
            <span className="font-serif uppercase text-gradient">
              We are distinctly different
            </span>
          </h2>

          <p className="mt-5 text-justify  text-[18px] leading-loose font-normal ">
          Madurai is a unique market, and our experience allows us to
            navigate its intricacies with ease. We understand the local business
            landscape, enabling us to provide insights and solutions that are
            finely tuned to the specific needs of our clients in the region. We
            do things differently. We value our client relationships and like to
            think that we provide a more personal approach to the development
            process.
          </p>
        </div>
      </section>
      {/* ==============Software Development Services ========== */}
      <section>
        <div className="px-16 py-10 mt-10 bg-gray-100 max-md:px-6">
          <h2 className="text-center text-[35px]  font-RobatoCondesed uppercase">
            What{" "}
            <span className="font-serif text-gradient ">
              {" "}
              Software Development{" "}
            </span>{" "}
            Services We Offer
          </h2>
          <section>
            <div className="flex font-RobatoCondesed mt-10 max-md:flex-col">
              <div className="w-[50%] h-auto max-md:w-full">
                <img
                  className="object-contain w-full h-96"
                  src={Imageone}
                  alt="loading"
                />
              </div>
              <div className="w-[50%] h-auto max-md:w-full">
                <div className="text-[30px]  font-medium uppercase">
                  <h2>Custom Software Development</h2>
                  <div className="h-1 bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full w-[80%] mt-3"></div>
                </div>
                <div className="mt-10">
                  {" "}
                  <ul className="text-justify">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        What is custom software development ?
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        What are examples of custom software ?
                      </span>
                    </li>

                    <li>
                      <p className="mt-10 text-justify text-[18px]">
                        The process of developing software according to
                        specifications and needs is known as custom software
                        development. We offer bespoke software development
                        services that take our client's needs into account.
                        Numerous instances of custom software development exist.
                        Any type of software can be modified to meet your needs.
                      </p>
                    </li>
                  </ul>{" "}
                </div>
              </div>
            </div>
          </section>
          {/* ==============Software Development Services ========== */}
          <section>
            <div className="flex mt-10 max-md:flex-col font-RobatoCondesed">
              <div className="w-[50%] h-auto max-md:w-full">
                <div className="text-[32px] text-justify font-medium uppercase">
                  <h2>E-commerce Software Development</h2>
                  <div className="h-1 bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full w-[80%] mt-3"></div>
                </div>
                <div className="mt-10">
                  {" "}
                  <ul className="text-justify">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        What is E-commerce Software?
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        What are the types of e-commerce software development
                        services?
                      </span>
                    </li>

                    <li>
                      <p className="mt-10 text-justify text-[18px]">
                        At FindWay Digital we understand the dynamic nature of the
                        e-commerce landscape. Whether you are a startup looking
                        to establish your online presence or an established
                        business aiming to enhance your e-commerce capabilities,
                        our team is here to assist you.
                      </p>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="w-[50%] h-auto max-md:w-full">
                <img
                  className="object-contain w-full h-96"
                  src={Imagetwo}
                  alt="loading"
                />
              </div>
            </div>
          </section>

          {/* ============== CRM Software Development ========== */}

          <section>
            <div className="flex max-md:flex-col font-RobatoCondesed">
              <div className="w-[50%] h-auto max-md:w-full">
                <img
                  className="object-contain w-full h-96"
                  src={ImageThree}
                  alt="loading"
                />
              </div>
              <div className="w-[50%] max-md:w-full h-auto mt-16">
                <div className="text-[32px] uppercase font-medium">
                  <h2>CRM Software Development</h2>
                  <div className="h-1 bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full w-[80%] mt-3"></div>
                </div>
                <div className="mt-10">
                  {" "}
                  <ul className="text-justify">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        What is CRM Software Development?
                      </span>
                    </li>

                    <li>
                      <p className="mt-10 text-justify text-[18px]">
                        Our bespoke CRM solutions are made to match the unique
                        requirements of your company and are intended to improve
                        customer engagement, optimize workflows, and increase
                        overall productivity. We can help with everything, from
                        lead management to customer support.
                      </p>
                    </li>
                  </ul>{" "}
                </div>
              </div>
            </div>
          </section>

          {/* ============== Web Application Development ========== */}
          <section>
            <div className="flex mt-10 font-RobatoCondesed max-md:flex-col">
              <div className="w-[50%] h-auto max-md:w-full">
                <div className="text-[32px]  font-medium uppercase">
                  <h2>Web Application Development</h2>
                  <div className="h-1 bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full w-[80%] mt-3"></div>
                </div>
                <div className="mt-10">
                  {" "}
                  <ul className="text-justify">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        What is a web application development service?
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        What are the types of web applications?
                      </span>
                    </li>

                    <li>
                      <p className="mt-10 text-justify text-[18px]">
                        Web-based solutions that promote an ideal user
                        experience are developed with the aid of web apps, or
                        web application development services. There are many
                        different kinds of web application development; some of
                        the more popular ones are portal, e-commerce,
                        single-page, dynamic, and static applications.
                      </p>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="w-[50%] h-auto max-md:w-full">
                <img
                  className="object-contain w-full h-96"
                  src={ImageFour}
                  alt="loading"
                />
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* ============== CRM Software Development ========== */}
      <section>
        <div className="px-16 font-RobatoCondesed mt-10 max-md:px-6">
          <div>
            <h2 className="text-center  text-[32px]  uppercase">
              Why{" "}
              <span className="font-serif text-gradient"> Honey Iconcs </span>{" "}
              Software is Different from Other Software Development Companies in
              Madurai
            </h2>
            <p className="mt-10 text-center text-[18px] leading-loose max-md:text-justify">
              Discover the Honey Icons Software difference – where local
              expertise, innovation, and customer-centricity converge to
              transform your software development experience. Contact us today
              to explore how we can elevate your business through our unique
              approach to software development in Madurai.
            </p>
          </div>

          <section>
            <div className="flex max-md:flex-col ">
              <div className="w-[60%] max-md:w-full">
                {" "}
                <div className="mt-10 text-justify">
                  {" "}
                  <ul className="text-justify">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal ">
                        Our team is not only attuned to the unique challenges
                        faced by businesses in Madurai but is also
                        well-versed in international best practices, ensuring
                        that our solutions transcend geographical boundaries.
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        We pride ourselves on delivering bespoke software
                        solutions that are tailor-made for each client.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Honey Icons Software is synonymous with innovation.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Throughout the development journey, we maintain open
                        lines of communication, keeping you informed at every
                        stage.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        This iterative approach ensures that your software is
                        developed incrementally, with regular feedback loops,
                        resulting in a final product that exceeds expectations.
                      </span>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="w-[40%] max-md:w-full">
                <img src={ImageFive} alt="" />
              </div>
            </div>
          </section>
        </div>
      </section>

      <section>
        <div className="px-16 py-5 mb-10 bg-gray-100 max-md:px-6 font-RobatoCondesed">
          <div className="">
            <h2 className="text-center pt-10 font-medium mb-10 text-gradient text-[35px]">
              {" "}
              FAQs{" "}
            </h2>
            <div className="mb-10 space-y-4">
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900 ">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What is the software development life cycle (SDLC)?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  SDLC is a process used to design, develop, test, and deploy
                  software. It typically includes phases like requirements
                  gathering, design, coding, testing, and maintenance.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What programming languages are commonly used in software
                  development?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Popular programming languages include Python, Java,
                  JavaScript, C++, and C# depending on the type of application.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What is Agile development?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Agile is an iterative and collaborative approach to software
                  development, emphasizing flexibility, customer feedback, and
                  continuous improvement.
                </p>
              </details>
              <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  How do I choose between custom development and off-the-shelf
                  software?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  The choice depends on specific business needs. Custom
                  development offers tailored solutions, while off-the-shelf
                  software provides quicker implementations for common
                  requirements.
                </p>
              </details>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SoftwareDevelopment;
