import React from "react";
import StaticImage from "../images/Web-development/Static-images-removebg.png";
import Dynaminamic from "../images/Web-development/Dynamic-website.png";
import Redsigne from "../images/Web-development/Redesigne.png";
import Tick from "../images/checkbox (1).png";
import StaticImageTwo from "../images/Web-development/UI-UX-Designing.png";
import DynamicImageTwo from "../images/Web-development/web-development (1).png";
import RedsigneImageTwo from "../images/Web-development/user-friendly-website-development.png";
import Image from "../images/Web-development/Image-new-one.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const WebDevelopment = () => {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Website Designing Company in Madurai</title>
          <link
            rel="canonical"
            href="
            www.honeyiconics.com/Website-design/
"
            content="Discover the epitome of web design excellence with our Madurai-based company. As the premier website design company in Coimbatore, we bring your digital dreams to life"
          />

          <meta name="description" content="Honey Iconics is a Leading website designing company in Madurai. Stunning, user-friendly websites that drive results. Contact us for tailored web solutions." />
        </Helmet>
      </div>
      <section>
        <section>
          <div>
            <section class="wrapper pb-10 mb-20 ">
              <div id="stars1"></div>
              <div id="stars2"></div>
              <div id="stars3"></div>

              <div className="flex pt-10 mb-10 max-lg:flex-col">
                <div className="w-[100%] pt-20 max-lg:w-full  max-lg:pl-none  ">
                  <h1
                    id="title"
                    className="leading-snug font-bold text-center uppercase  text-white max-sm:text-[2rem] text-[3.8rem]"
                  >
                    <h1>Website Design Company in Madurai</h1>
                  </h1>
                  <p className="mt-6 px-20 max-sm:text-[15px] max-sm:px-6 font-RobatoCondesed  font-normal  text-[18px]  text-center text-white ">
                    Searching For Best Website Design company Services In
                    Madurai To Create A Modern Website For Your Business Or
                    Startup? Take Quick Action And Hire Findway Website
                    Development Company In Madurai For The Best Web
                    Development Services At Affordable Prices.
                  </p>

                  <center className="">
                    <Link to={"/contact"}>     <button className="btn-hover mb-5 color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                      <span className=" text-[18px] font-medium">
                        Contact Us
                      </span>
                    </button> </Link>
                
                  </center>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* ================ Website Development Company ================ */}
        <section>
          <div>
            <div className="px-16  font-RobatoCondesed max-md:px-6">
              <h2 className=" text-[35px] uppercase max-md:text-[18px]">
                India’s
                <span className="font-serif mr-3 ml-3 text-gradient">
                  Leading Website Development Company
                </span>
                Business Website Now!
              </h2>
              <p className="text-[18px]  leading-loose text-justify mt-10 max-sm:text-justify">
                Welcome to Findway Leading Website Developement Company,
                where we take pride in being India's leading website development
                company. If you're ready to propel your business to new heights,
                we're here to help you create a powerful online presence that
                captivates your audience and drives success.
                <br />
                <br />
                Unlock the full potential of your business with India's top
                website development company. Elevate your online presence,
                attract customers, and boost revenue. Get started today with our
                expert web development services! We understand that every
                business is unique. Our website development services are
                tailored to your specific requirements, ensuring that your
                online presence reflects the essence of your brand.
                <br />
                <br />
                With years of experience in the industry, we bring a wealth of
                expertise to every project. Our seasoned team of web developers
                is committed to delivering top-notch solutions tailored to your
                business needs.In the dynamic world of the internet, staying
                ahead is crucial. Our commitment to innovation ensures that your
                website is not just a digital presence but a strategic asset,
                equipped with the latest technologies and trends.
              </p>
            </div>
          </div>
        </section>
        {/* ================ Website Development Company ================ */}

        <section>
          <div className="px-16 mt-16 max-md:px-6 font-RobatoCondesed">
            <div>
              <h2 className="text-[35px] uppercase  font-medium max-sm:text-[18px]">
                <span className="font-serif ml-3 mr-3  text-gradient">
                  Top Quality Website Development Services
                </span>
                In India
              </h2>
              <p className="mt-5 font-serif italic  text-[25px] font-medium">
                {" "}
                Stumped Whether Your Business Needs WEBSITE Or NOT?
              </p>
              <p className="mt-5 font-serif italic  text-[25px] font-medium">
                Are you grappling with the question of whether your business
                truly needs a website? In a world driven by digital
                interactions, the answer is a resounding yes. Here are
                compelling reasons why having a website is not just beneficial
                but crucial for the success and growth of your business.
              </p>
            </div>
            <p className="mt-5 pr-96"></p>
            <div className="flex justify-between gap-10 mt-10 max-md:flex-col">
              <div className="w-[50%] max-md:w-full p-6 bg-white border-2 border-text-gradient hover:shadow-md hover:shadow-gray-400">
                <div>
                  <ul>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        Create Your Web Identity
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Easy To Change And Update
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Be Accessible To New Customers
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-[50%] max-md:w-full border-text-gradient p-6 bg-white border-2 hover:shadow-md hover:shadow-gray-400">
                <div>
                  <ul>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        Global Presence
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Credibility and Trust
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        24/7 Accessibility
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-[50%] max-md:w-full p-6 border-text-gradient bg-white border-2 hover:shadow-md hover:shadow-gray-400">
                <div>
                  <ul>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        Marketing and Visibility
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Showcasing Products or Services
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Adaptability to Changing Trends
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ================ Why Should You Invest In Website Development Services In India? ================ */}
        <section>
          <div className="px-16 font-RobatoCondesed mt-20 max-md:px-6">
            <div>
              <h2 className="text-[35px] uppercase  text-justify ">
                Why Should You
                <span className="font-serif ml-3 mr-3 text-gradient">
                  Invest In Website Development
                </span>
                Services In Madurai ?
              </h2>
            </div>
            <p className="mt-5 text-[18px]  text-justify leading-9">
            Madurai-based developers have a deep understanding of the local
              business landscape.Choosing a local website development service
              means you can enjoy personalized attention to your project.
            </p>

            <p className="mt-5 text-[18px]  text-justify leading-9">
            Madurai is known for offering cost-effective solutions without
              compromising on quality. By investing in website development
              services in Madurai, you can benefit from competitive pricing,
              making it a budget-friendly choice for businesses looking to
              enhance their online presence. Proximity facilitates efficient
              communication and collaboration.
            </p>

            <p className="mt-5 text-[18px]  text-justify leading-9">
            Madurai has a thriving IT ecosystem, with developers staying
              abreast of emerging technologies. This ensures that your site
              remains up-to-date, secure, and functioning optimally,
              contributing to a seamless online experience for your visitors.
            </p>
          </div>
        </section>
        {/* ================ Website Development Services ================ */}

        <section>
          <div className="px-16 py-16 font-RobatoCondesed mt-10 max-md:px-6">
            <div>
              <h2 className="text-[35px] uppercase max-sm:text-[35px] max-sm:text-justify text-center font-medium ">
                <span className="ml-3 mr-3 font-serif text-gradient">
                  Webite Development
                </span>
                Services
              </h2>

              <div id="example" className="mt-16 ">
                <ul class="list flex justify-between max-md:flex-col gap-10">
                  <li class="shopping w-full h-full  flex flex-col">
                    <img
                      className="z-20 h-72 object-contain"
                      src={StaticImageTwo}
                      alt="loading"
                    />
                    <span class="title text-[33px]  text-center mt-6  font-medium font-serif">
                      Static Website
                    </span>
                    <p class="subtitle mt-6  flex-grow h-full text-justify   leading-loose">
                      Static websites, with their simplicity and streamlined
                      approach, offer distinct advantages that cater to specific
                      business needs. One notable advantage is their
                      cost-effectiveness, making them an ideal choice for small
                      businesses and startups operating within budget
                      constraints. The fast loading times of static websites
                      contribute to an improved user experience. Additionally,
                      their ease of maintenance allows for quick updates,
                      offering businesses a straightforward solution for
                      establishing a strong online presence.
                    </p>
                  </li>
                  <li class="personal flex flex-col h-full w-full">
                    <img
                      className="z-20 h-72 object-contain"
                      src={DynamicImageTwo}
                      alt=""
                    />
                    <span class="title text-[33px] text-center mt-6  font-medium font-serif">
                      Dynamic Website
                    </span>
                    <p class="subtitle mt-6  flex-grow h-full text-justify   leading-loose">
                      Dynamic websites, characterized by their interactive and
                      flexible nature, offer a myriad of advantages that propel
                      businesses into the forefront of the digital landscape.
                      One key advantage lies in their ability to engage users
                      with real-time content updates, creating a dynamic and
                      personalized experience. This interactivity fosters longer
                      user interactions, increasing the chances of conversion
                      and establishing a positive online brand perception.
                    </p>
                  </li>
                  <li class="work h-full flex flex-col w-full">
                    <img
                      className="z-20 h-72 object-contain"
                      src={RedsigneImageTwo}
                      alt=""
                    />
                    <span class="title text-[33px] text-center  mt-6 font-medium font-serif">
                      Website Redesigning
                    </span>
                    <p class="subtitle text-justify mt-6  flex-grow h-full leading-loose">
                      Website redesigning stands as a strategic maneuver,
                      offering a multitude of advantages for businesses aiming
                      to enhance their digital presence. One significant
                      advantage lies in the potential for an improved visual
                      appeal. A redesigned website aligns with contemporary
                      design trends, presenting a fresh and captivating look
                      that resonates with modern users. Beyond aesthetics, the
                      process enables businesses to enhance functionality,
                      ensuring a seamless user experience and improved site
                      performance.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* ================ Why choose Static Website ? ================ */}
        <section>
          <div className="px-16 font-RobatoCondesed py-16 mt-10 bg-gray-100 max-md:px-6">
            <div>
              <h2 className="text-[35px] font-medium text-center uppercase">
                Why Choose Our
                <span className="ml-3 mr-3 font-serif text-gradient">
                  Webite Development
                </span>
                Services
              </h2>
            </div>

            <section>
              <div className="flex mt-10 font-RobatoCondesed max-md:flex-col">
                <div className="w-[50%] max-md:w-full">
                  <center>
                    <img
                      className="object-cover w-full h-96 "
                      src={StaticImage}
                      alt="loading"
                    />
                  </center>
                </div>
                <div className="w-[50%] max-md:w-full">
                  <h2 className="font-medium uppercase text-[32px]">
                    Why choose Static Website ?
                  </h2>
                  <div className="h-1 mt-3 bg-text-gradient w-96 rounded-xl"></div>
                  <p className="mt-5 leading-loose text-justify">
                    Choosing us for your static website needs ensures a seamless
                    and cost-effective online presence that aligns with your
                    business goals. Our commitment to delivering high-quality
                    static websites is rooted in the understanding that
                    simplicity can be powerful. With a focus on efficiency and
                    reliability, our expert team crafts static websites that
                    load swiftly, providing visitors with a smooth and
                    satisfying browsing experience. Opting for our services
                    means choosing a cost-effective solution without
                    compromising on quality. We prioritize simplicity and ease
                    of maintenance, enabling you to effortlessly update and
                    manage your content. Our static websites are not only
                    visually appealing but also highly secure, mitigating
                    potential vulnerabilities.
                  </p>
                  <p className="mt-5 leading-loose">
                    Hosting flexibility and search engine friendliness are
                    integral aspects of our approach, ensuring that your website
                    not only stands out but also performs exceptionally well.
                  </p>
                  <div>
                    <h1>CSS TODO List Design Inspiration</h1>
                  </div>
                </div>
              </div>
            </section>
            {/* ================ Why choose Dynamic Website ? ================ */}
            <section>
              <div className="flex mt-32 max-md:flex-col">
                <div className="w-[50%] max-md:w-full">
                  <img
                    className="object-cover w-full h-96 "
                    src={Dynaminamic}
                    alt="loading"
                  />
                </div>
                <div className="w-[50%] max-md:w-full">
                  <h2 className="font-medium uppercase text-[32px] text-justify">
                    Why choose Dynamic Website ?
                  </h2>
                  <div className="h-1 mt-3 bg-text-gradient w-96 rounded-xl"></div>
                  <p className="mt-5 leading-loose text-justify">
                    Choosing us for your dynamic website needs is a strategic
                    decision rooted in our commitment to delivering
                    cutting-edge, interactive, and adaptive online solutions. We
                    understand that in the dynamic digital landscape, businesses
                    require websites that can evolve with their changing needs.
                    Our experienced team of developers harnesses the power of
                    the latest technologies to create dynamic websites that go
                    beyond static content, engaging users with real-time updates
                    and personalized experiences. When you choose us, you opt
                    for a partner that prioritizes seamless integration of
                    databases, ensuring robust functionality and user
                    authentication for a secure online environment.
                  </p>
                  <p className="mt-5 leading-loose">
                    Our dynamic websites are tailored to align with the unique
                    objectives of your business, fostering increased user
                    engagement and enhancing your online presence.
                  </p>
                </div>
              </div>
            </section>

            {/* ================ Why choose Redsigne Website ? ================ */}

            <section>
              <div className="flex font-RobatoCondesed mt-36 max-md:flex-col">
                <div className="w-[50%] max-md:w-full">
                  <img
                    className="object-cover w-full h-96 "
                    src={Redsigne}
                    alt="loading"
                  />
                </div>
                <div className="w-[50%] max-md:w-full">
                  <h2 className="font-medium uppercase text-[32px]">
                    Why Choose Website Redesigning ?
                  </h2>
                  <div className="h-1 mt-3 bg-text-gradient w-96 rounded-xl"></div>
                  <p className="mt-5 leading-loose text-justify">
                    Choosing us for your website redesigning needs is an
                    investment in revitalizing your online presence and ensuring
                    that your digital image aligns seamlessly with your evolving
                    business objectives. Our commitment to excellence in website
                    redesigning is grounded in the understanding that an updated
                    and visually appealing website is crucial for staying
                    relevant in the dynamic online landscape. When you choose
                    us, you opt for a team of skilled professionals who
                    prioritize not only aesthetic enhancements but also improved
                    functionality and user experience. Our strategic redesigning
                    process goes beyond surface-level changes; we delve into the
                    core of your website, ensuring that it adapts to the latest
                    design trends and technological advancements.
                  </p>
                  <p className="mt-5 leading-loose">
                    Whether it's a complete overhaul or targeted improvements,
                    our team tailors the redesign to align with your brand
                    identity, capture audience attention, and foster a positive
                    online perception.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* ================   FEATURES ================ */}

        <section>
          <div className="flex px-16 mt-28 max-md:flex-col">
            <div className=" w-[30%] font-RobatoCondesed max-md:w-full">
              <h2 className="text-center text-blue-400 text-[18px]">
                FEATURES
              </h2>
              <h2 className="text-center text-[26px]  text-blue-900 font-medium">
                Some features provided for Website redesign
              </h2>
              <p className="text-center text-[18px] mt-5 ">
                Website redesign involves enhancing and updating various
                elements to improve the overall performance, aesthetics, and
                functionality of a website.
              </p>
            </div>
            <section className="py-6 ">
              <div className="container flex flex-col justify-center p-4 mx-auto ">
                <div className="grid font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                  <div className="px-10 text-[18px]  py-5 font-medium shadow-md rounded-xl  shadow-gray-300">
                    Responsive Design
                  </div>
                  <div className="px-5 text-[18px] py-5 font-medium shadow-md rounded-xl   shadow-gray-300">
                    Attractive UI & Layouts
                  </div>
                  <div className="px-5 text-[18px] py-5 font-medium shadow-md rounded-xl   shadow-gray-300">
                    Powerful Customization
                  </div>

                  <div className="px-10 text-[18px] py-5 font-medium shadow-md rounded-xl   shadow-gray-300">
                    Flexible & Scalable
                  </div>
                  <div className="px-10 text-[18px] py-5 font-medium shadow-md rounded-xl   shadow-gray-300">
                    Fast Loading
                  </div>
                  <div className="px-10 text-[18px] py-5 font-medium shadow-md rounded-xl   shadow-gray-300">
                    SEO Friendly
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* ================   FEATURES ================ */}
        <section>
          <div className="px-16 font-RobatoCondesed mt-16 max-md:px-6">
            <div>
              <h2 className="text-center uppercase text-[35px] max-sm:text-[30px] font-medium ">
                Fast, Secure, SEO & Mobile Friendly Website Development Services
                In India
              </h2>
              <p className="mt-5 font-medium text-center text-[20px] italic font-serif">
                Customized Website Development Services In India Across
                Different Industrial Sectors
              </p>
              <section className="py-6 ">
                <div className="container flex flex-col justify-center p-4 mx-auto">
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 sm:grid-cols-2">
                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i className="fas fa-hand-holding-heart"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        .E-Commerce Excellence
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-cart-shopping"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        Healthcare Harmony
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-handshake"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Manufacturing Mastery
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-graduation-cap"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        Educational Elevation
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-suitcase"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Financial Finesse
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-gavel"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5   pt-5">
                        Hospitality Harmony
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-building"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Tech Savvy Solutions
                      </p>
                    </div>

                    <div className="text-center border-2 border-gray-200 rounded-lg hover:shadow-md hover:shadow-gray-500 ">
                      <h2 className="text-[50px] text-gradient">
                        <i class="fa-solid fa-trowel"></i>
                      </h2>
                      <p className="text-center pb-5  text-[25px] px-5  pt-5">
                        Beyond Boundaries
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        {/* ================ Why SLN Software is Different from Other Software Development Companies in Delhi ================ */}
        <section>
          <div className="font-RobatoCondesed">
            <div className="px-16 mt-28 max-md:px-6">
              <h2 className="text-center text-[35px] uppercase">
                Why
                <span className="font-serif ml-2 mr-2 font-normal text-gradient ">
                  Honey Iconics Software
                </span>
                is Different from Other Software Development Companies in
                Madurai
              </h2>
              <p className="mt-10 text-center text-[18px] leading-9 max-sm:text-justify">
                Discover the distinctive qualities that make Honey Iconics
                Software stand out among software development companies in
                Madurai. From innovative solutions to unwavering commitment,
                explore why choosing us ensures a unique and unparalleled
                experience
              </p>

              <div className="flex gap-5 mt-16 max-md:flex-col">
                <div className="w-[70%] max-md:w-full">
                  <ul className="text-justify ">
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-3 ml-2 text-lg font-normal">
                        At Honey Iconics Software, we don't believe in
                        one-size-fits-all solutions. What distinguishes us is
                        our commitment to crafting innovative and customized
                        software solutions that precisely align with the unique
                        needs and goals of your business.
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Your success is our success, and we go the extra mile to
                        ensure that our solutions contribute meaningfully to
                        your growth and digital transformation.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Honey Iconics Software's versatility spans across
                        various industries. Whether you're in e-commerce,
                        healthcare, manufacturing, or any other sector, our
                        expertise extends to delivering tailored software
                        solutions that address the specific challenges and
                        opportunities unique to your industry.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Staying ahead in the fast-evolving tech landscape is our
                        forte. We embrace the latest technologies and
                        frameworks, ensuring that our software solutions are not
                        just current but also future-proof.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Unlike many software development companies, we offer
                        comprehensive end-to-end services.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        We provide dedicated support and maintenance services to
                        ensure that your software continues to operate
                        seamlessly, and we are ready to address any evolving
                        needs or challenges that may arise.
                      </span>
                    </li>
                    <li className="flex ">
                      <img className="h-6 mt-1" src={Tick} alt="loading" />
                      <span className="pb-2 ml-2 text-lg font-normal">
                        Honey Iconics Software has successfully delivered
                        impactful solutions for a diverse range of clients. Our
                        satisfied clients are a testament to our dedication to
                        excellence and our ability to consistently surpass
                        expectations.
                      </span>
                    </li>

                    <li className="text-lg">
                      In conclusion, what sets Honey Iconics Software apart from
                      other software development companies in Madurai is our
                      unwavering commitment to innovation, client-centricity,
                      versatility, and the delivery of cutting-edge solutions
                      that empower businesses to thrive in the digital age.
                      Choose us for a transformative software development
                      experience tailored to your unique business journey.
                    </li>
                  </ul>
                </div>
                <div className="w-[30%] max-md:w-full">
                  <img
                    className="object-cover w-auto h-96"
                    src={Image}
                    alt="loading"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ================ Benefits Of Having Us By Your Side For UI And UX Design ================ */}

        <section>
          <div className="px-16 font-RobatoCondesed mt-10 max-md:px-6">
            <div>
              <h2 className="text-[35px] uppercase">
                Benefits Of Having Us By Your Side For
                <span className="font-serif ml-3 text-gradient">
                  UI And UX Design
                </span>
              </h2>

              <p className="mt-5 text-[18px]  leading-9 text-justify">
                At Honey Iconics, In the ever-evolving digital landscape, the
                significance of exceptional UI (User Interface) and UX (User
                Experience) design cannot be overstated. Choosing us for your UI
                and UX design needs brings forth a multitude of benefits,
                setting your digital presence on a trajectory of success.
              </p>
            </div>
          </div>
        </section>

        {/* ================ Benefits Of Having Us By Your Side For UI And UX Design ================ */}

        <section>
          <div className="px-16 font-RobatoCondesed mt-10 mb-10 max-md:px-6">
            <div className="">
              <h2 className="text-[35px] uppercase">
                Want To Learn More About Our
                <span className="mr-5 ml-3 font-serif text-gradient">
                  Website Development Company
                </span>
                In India?
              </h2>

              <p className="mt-5 text-[18px]  leading-9 text-justify">
                Findway is the website development company in Madurai.
                Whether you're a startup looking for a solid online foundation
                or an established business seeking a digital facelift, our
                website development company in Madurai is ready to bring your
                vision to life. Explore the depths of our expertise, innovation,
                and commitment to excellence. Contact us today to embark on a
                journey of digital transformation that sets your business apart
                in the vibrant market of Madurai.
              </p>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default WebDevelopment;
