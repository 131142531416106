import React from "react";
import { Link } from "react-router-dom";
import Offpage from "../../images/digitalmarketing/off-page-seo-removebg-preview.png";
// import LinkBuildinImage from "../../images/digitalmarketing/LinkBuilding-Image.jpg";
import SeoImageFive from "../../images/digitalmarketing/SEO-Image-five.jpg";
import Question from "../../images/digitalmarketing/Question.jpg";
import Blog from "../../images/digitalmarketing/Blog-removebg-preview.png";
import LinkBuilding from "../../images/digitalmarketing/Link-building-removebg-preview.png";
import LinkBuildingImagefive from "../../images/digitalmarketing/LinkImageFive.png";
import LinkBuildingImageSix from "../../images/digitalmarketing/LinkImageSix.png";
import BackLinkImage from "../../images/digitalmarketing/BackLinkSearchImage.png";
import AlertImage from "../../images/seo-images/Alart-image.jpg";
import Tick from "../../images/checkbox.png";
import { Helmet } from "react-helmet";

const Offpageseo = () => {
  return (
    <>
      <div>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Off-Page SEO Services in Coimbatore</title>

            <meta name="description" content="Boost your online influence with expert Off-Page SEO services from Honey Iconics. Our strategies go beyond your website, focusing on link building, social signals, and online reputation management. Enhance your brand's credibility and visibility in the digital landscape. Partner with us for off-page optimization that drives long-term success. " />
            <link
              rel="canonical"
              href="
            https://honey-iconicss.com/on-page-seo/
            "
            />
          </Helmet>
        </div>
        <div className="h-auto relative pb-48 bg-slate-900 ">
          <div className="flex w-auto h-auto auto max-lg:flex-col ">
            <div className="lg:w-[50%] h-auto md:auto">
              <h1 className="pl-10 pt-10 pb-2 font-RobatoCondesed text-white  text-[35px] font-semibold ">
                OFF <span className="text-gradient "> PAGE </span> SEO
              </h1>
              <div className="pb-5">
                <ul className="flex font-RobatoCondesed h-8 pl-10 space-x-2 text-white">
                  <li className="flex items-center text-[12px] ">
                    <Link to={"/"}> HOME </Link>
                    <span className="ml-2 text-whitetext-[12px]">/</span>
                  </li>
                  <li className="flex items-center text-[12px] ">
                    ON Page Seo
                  </li>
                </ul>
              </div>
            </div>

            <div className="w-full h-auto pb-2 pt-11 lg:pl-10 lg:pr-28 max-md:px-5 max-md:pt-3 md:px-3">
              <div className="flex lg:w-[100%] h-auto rounded-full shadow-md lg:p-2 md:p-2 bg-slate-200 shadow-text-gradient max-md:p-2">
                <div className="h-auto p-2 text-white bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red rounded-full max-md:w-[45%] font-RobatoCondesed lg:w-[25%] max-sm:text-[11px] text-center font-se md:w-[30%]">
                  OFF Page Seo
                </div>
                <div className="overflow-hidden">
                  <div className="pt-2 font-RobatoCondesed pl-2 font-medium marquee">
                    <p>
                      The Best SEO Agency in India which is located in
                      coimbatore
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-auto ">
            <h1 className="text-center uppercase font-RobatoCondesed text-[35px] font-se text-white">
              What Is
              <span className="ml-2 mr-2 font-serif text-gradient">
                Off-Page
              </span>
              SEO ?
            </h1>
          </div>
          <div className="flex px-10 max-lg:flex-col max-md:px-6">
            <div className="w-[50%] font-RobatoCondesed h-auto pt-11 text-[18px] leading-8 text-white max-lg:w-full text-justify">
              Off-page SEO involves various strategies that help improve a
              website’s search engine rankings beyond what’s actually on the
              site. This includes building high-quality backlinks, using social
              media to advertise the website, and reaching out to influencers to
              help extend the word. All of these methods help boost the
              website’s perceived authority and relevance to search engines,
              which can lead to higher rankings and more organic traffic. But
              remember, off-page SEO is just one part of the puzzle. It’s
              important to also focus on onpage seo optimization, technical SEO,
              and content marketing to develop a well-rounded strategy that will
              help drive traffic, increase conversions, and grow revenue.
            </div>
            <div className="w-[50%] h-96 max-lg:w-full max-sm:h-auto">
              <img
                className="object-contain w-full py-3 h-80 max-md:object-contain"
                src={Offpage}
                alt="loading"
              />
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className=" -bottom-2 absolute"
          >
            <path
              fill="white"
              fill-opacity="1"
              d="M0,32L30,48C60,64,120,96,180,138.7C240,181,300,235,360,266.7C420,299,480,309,540,266.7C600,224,660,128,720,122.7C780,117,840,203,900,197.3C960,192,1020,96,1080,74.7C1140,53,1200,107,1260,112C1320,117,1380,75,1410,53.3L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
            ></path>
          </svg>
        </div>
        {/* ============ Section Two ================ */}
        <section>
          <div>
            <div className="px-16 max-md:px-6 font-RobatoCondesed">
              <h1 className="text-[35px] mt-10 uppercase">
                The Importance Of
                <span className="font-serif ml-2 text-gradient">
                  Off Page SEO
                </span>
              </h1>

              <p className="mt-5 text-[18px]  font-medium">
                Here are some statistics that highlight the importance of
                Off-Page SEO :
              </p>

              <p className="mt-5 text-[18px] leading-8 text-justify">
                91% of all pages on the web receive no organic search traffic
                from Google. This emphasizes the importance of optimizing your
                website through OffPage SEO to rank high in search engine
                results pages (SERPs). <br /> <br /> A backlink profile with a
                high domain authority (DA) is associated with higher search
                engine rankings. This means that Off-Page SEO is crucial for
                improving your website’s DA and ranking higher in SERPs. <br />
                <br /> Over 70% of marketers believe that link-building is the
                most effective Off-Page SEO technique for improving search
                engine rankings.
              </p>
            </div>
          </div>
        </section>
        {/* ============ Section Three ================ */}
        <section className="mt-20">
          <div>
            <div className="px-16 max-md:px-6 font-RobatoCondesed">
              <h1 className="text-[35px] uppercase">
                What Is Included In
                <span className="ml-3 mr-3 font-serif text-gradient">
                  Off-Page SEO Services?
                </span>
              </h1>

              <p className="mt-5 font-RobatoCondesed text-[18px] leading-9 text-justify">
                Enhancing a website's search engine rankings outside of the site
                itself requires off-page SEO services. These services involve
                constructing trust-worthy backlinks, producing interesting
                social media content to advertise the website, reaching out to
                those with authority successfully, and implementing other
                techniques to raise brand awareness.
                <br /> <br />
                Guest blogging, social bookmarking, and local business listings
                are a few more off-page SEO strategies.
                <br />
                <br />
                Improving a website's perceived authority and relevance to
                search engines is the primary objective of off-page SEO, which
                can result in higher ranks and more organic visitors. Businesses
                may develop a comprehensive SEO plan that helps generate more
                quality visitors to their website and ultimately achieve their
                goals by integrating off-page optimization with on-page
                optimization, technical SEO, and content marketing.
              </p>
            </div>
          </div>
        </section>

        {/* ============ What AreLocal Citations?  ================ */}
        <section>
          <div className="mt-5 ">
            <div>
              <h1 className="text-center uppercase text-[35px]">
                What Are
                <span className="ml-3 font-serif text-gradient mr">
                  Local Citations?
                </span>
              </h1>
            </div>
            <div>
              <div className="px-16 mt-2 max-md:px-6 max-lg:w-full">
                <div className="text-[18px] leading-9 flex max-lg:flex-col">
                  <div className="w-[50%] max-lg:w-full font-RobatoCondesed text-justify pt-10 max-md:pt-2 max-md:w-full">
                    The most important aspect of search engine optimization that
                    businesses should be aware of is local citations. To put it
                    simply, online mentions of your company's name, address, and
                    phone number (NAP) on other websites are known as local
                    citations. Social media platforms, company directories, and
                    review websites are a few examples of these.
                    <br /> <br />
                    Search engines such as Google use local citations to confirm
                    the veracity and authenticity of your company's information.
                    The likelihood of your website ranking higher in local
                    search results increases with the consistency of these
                    references across many domains. For small and medium-sized
                    businesses trying to draw clients from their surrounding
                    area, this can be very useful.
                  </div>
                  <div className="w-[50%] h-auto max-lg:w-full">
                    <center>
                      {" "}
                      <img
                        className="h-96  object-contain"
                        src={SeoImageFive}
                        alt="loading"
                      />
                    </center>
                  </div>
                </div>
                <h1 className="text-[35px] font-RobatoCondesed uppercase mt-10">
                  How are
                  <span className="ml-3 mr-3 font-serif text-orange-500">
                    local citations managed?
                  </span>
                </h1>
                <p className="mt-5 font-RobatoCondesed text-[18px] leading-9 text-justify">
                  We perform a thorough local citation audit, gathering data on
                  local searchers and conducting a competitive analysis of local
                  businesses. We administer local listings in their entirety. We
                  update both the existing and new business listings in addition
                  to creating a new one. We also put up listing claims for
                  Google Maps and Google My Business Management.
                </p>
                <p className="mt-5 italic font-serif text-bold text-[20px]">
                  We provide current business information for Google My Business
                  page optimization
                </p>

                <section className="py-6 dark:bg-gray-800">
                  <div className="container flex flex-col justify-center p-4 mx-auto">
                    <div className="grid font-bold text-[18px] font-RobatoCondesed grid-cols-1 gap-4 lg:grid-cols-5 sm:grid-cols-2">
                      <div className="px-10  py-3 font-medium rounded-full shadow-md shadow-gray-500">
                        Business name
                      </div>
                      {/* == */}
                      <div className="px-10 py-3 font-medium rounded-full shadow-md shadow-gray-500 ">
                        Website URL
                      </div>
                      {/* ==== */}
                      <div className="px-10 py-3 font-medium  rounded-full shadow-md shadow-gray-500 ">
                        Location
                      </div>
                      {/* === */}
                      <div className="px-10 py-3 font-medium  rounded-full shadow-md shadow-gray-500 ">
                        Phone number
                      </div>
                      {/* === */}
                      <div className="px-10 py-3 font-medium  rounded-full shadow-md shadow-gray-500 ">
                        Review
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/* ============ Section six ================ */}
        <section>
          <div className="px-16 py-10 bg-gray-100 max-md:px-6">
            <h1 className="text-[35px] font-RobatoCondesed uppercase">
              Off-Page SEO Benefits From
              <span className="ml-3 mr-3 font-serif text-gradient">
                Local Classified Advertising
              </span>
            </h1>
            <p className="leading-loose text-[18px] mt-5 font-RobatoCondesed max-sm:text-justify">
              Utilizing local classified ads is one of the most effective
              techniques to increase off-page SEO. Local classifieds are
              internet listings that let companies promote their goods and
              services in a particular area. These directories give companies a
              way to be seen by those who might be looking for their products or
              services locally. You may increase brand recognition and drive
              more traffic to your website by placing an advertisement for your
              company in these regional directories. Additionally, by offering
              hyperlinks that direct visitors to your website, local classifieds
              can boost the search engine rating of your website.
            </p>
          </div>
          {/* ------Q&A Submission---- */}
          <div>
            <div className="flex px-16  mt-10 max-md:px-6 max-lg:flex-col">
              <div className="w-[50%] max-lg:w-full">
                <center>
                  {" "}
                  <img
                    className="object-contain h-96"
                    src={Question}
                    alt="loading"
                  />{" "}
                </center>
              </div>
              <div className="w-[50%] max-lg:w-full">
                <h1 className="text-[35px] font-RobatoCondesed pt-12 text-gradient">
                  Submissions for Q&A
                </h1>
                <p className="text-[18px] font-RobatoCondesed leading-9 text-justify mt-10">
                  Implementing Q&A submissions for Off-Page SEO is a helpful
                  strategy to increase website traffic and visibility. Answers
                  to questions submitted by users on various websites, such as
                  Quora, Yahoo Answers, Reddit, and others, are included in Q&A
                  submissions. In your niche, you can establish yourself as an
                  authority and increase website traffic by offering insightful
                  answers to pertinent queries. Q&A submissions allow you to
                  build backlinks to your website, which makes them an excellent
                  off-page SEO technique.
                </p>
              </div>
            </div>
          </div>
          {/* ------Guest Bloging---- */}
          <section>
            <div
              className="flex px-16 mt-10 max-lg:flex-col max-md
            :px-6"
            >
              <div className="w-[50%] font-RobatoCondesed  max-lg:w-full">
                <h1 className="text-[35px] uppercase text-gradient ">
                  Bloggers on Guest Sites
                </h1>
                <p className="mt-10 text-[18px] font-RobatoCondesed leading-loose text-justify">
                  A well-known strategy for improving visibility and traffic is
                  guest blogging. It involves creating excellent content that
                  links back to your website from other websites. This strategy
                  can raise your search engine rankings by generating valuable
                  backlinks and building relationships with other website
                  owners. The benefits of guest posting are various. First of
                  all, it gives you the chance to reach a fresh audience that is
                  already interested in the subject of your writing. Second,
                  showing your knowledge and skills through well-written
                  material can assist in establishing you as an authority in
                  your field. Thirdly, guest blogging offers a chance to receive
                  traffic referrals from the host site's intended age group.
                  Invited blogging should be done carefully, focusing on quality
                  over quantity.
                </p>
              </div>

              <div className="w-[50%] max-lg:w-full max-lg:hidden">
                <center>
                  {" "}
                  <img className="h-96 " src={Blog} alt="loading" />{" "}
                </center>
              </div>
            </div>
            <div className="px-16 max-md:px-6"></div>
          </section>

          {/* ------Guest Bloging---- */}

          <section>
            <div>
              <div className="flex px-16 max-lg:flex-col max-md:px-6">
                <div className="w-[50%] max-lg:w-full">
                  <center>
                    {" "}
                    <img
                      className="h-96"
                      src={LinkBuilding}
                      alt="loading"
                    />{" "}
                  </center>
                </div>
                <div className="w-[50%] font-RobatoCondesed max-lg:w-full ">
                  <h1 className="text-[35px]  uppercase pt-16 text-gradient">
                    Broken Backlink Building
                  </h1>
                  <p className="mt-5  text-[18px] leading-loose text-justify">
                    Finding broken links on other websites and swapping them out
                    for live links pointing to your website is known as "broken
                    backlink building." The concept for this strategy is simple.
                    If a website has a broken link, it presents an opportunity
                    for you to intervene and provide a useful and relevant
                    resource in its place. By doing this, you can raise your
                    website's search engine rankings, draw in more visitors, and
                    build beneficial connections with other webmasters.
                  </p>
                </div>
              </div>
              <p className="text-[18px] max-md:px-6 px-16 font-RobatoCondesed  text-justify leading-loose ">
                The first step in creating broken backlinks is usually
                identifying relevant websites with high domain authority that
                are in your sector or niche and have broken links. The next step
                is to produce material that is connected to or comparable to the
                page that had the broken link. This could have an impact on
                producing original research, creating in-depth manuals or
                tutorials, or creating educational infographics or videos.
              </p>
            </div>
          </section>
          {/* ------Resource Link Building---- */}
          <section>
            <div className="mt-10">
              <div className="px-16 max-md:px-6">
                <div className="flex max-lg:flex-col ">
                  <div className="w-[50%] max-lg:w-full">
                    <h1 className="text-[35px] font-RobatoCondesed uppercase text-gradient">
                      Establishing Resource Links
                    </h1>
                    <p className="text-[18px] font-RobatoCondesed leading-loose text-justify mt-10">
                      One of the best strategies to improve the search engine
                      optimization (SEO) of your website is resource link
                      development. It entails creating helpful content for your
                      website, like tutorials or guidelines, and then contacting
                      other websites within your sector to request a backlink to
                      your resource. This can enhance your resources in addition
                      to allowing you to receive additional visitors from these
                      external links. In addition to increasing the amount of
                      traffic that comes from these external links, this can
                      eventually raise your search engine results.
                    </p>
                  </div>
                  <div className="w-[50%] max-lg:w-full">
                    <center>
                      {" "}
                      <img
                        className="h-96"
                        src={LinkBuildingImagefive}
                        alt=""
                      />{" "}
                    </center>
                  </div>
                </div>
                <div className="">
                  <p className="text-[18px] leading-loose text-justify">
                    Decide which subjects would be appropriate for other
                    websites in your field before you begin resource link
                    development. Next, produce top-notch content that gives
                    readers useful information and ideas they can use. After
                    producing the material, use social media messaging or email
                    outreach to connect with other websites in your niche and
                    request possible link exchanges. To avoid appearing
                    unwanted, make sure every communication you send is
                    personalized.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* ------Link Roundups---- */}
          <section>
            <div className="px-16 mt-10 max-md:px-6">
              <div className="flex max-lg:flex-col">
                <div className="w-[50%] max-lg:w-full">
                  <center>
                    {" "}
                    <img
                      className="object-contain h-96"
                      src={LinkBuildingImageSix}
                      alt=""
                    />{" "}
                  </center>
                </div>
                <div className="w-[50%] max-lg:w-full">
                  <h1 className="text-[35px] uppercase font-RobatoCondesed text-gradient">
                    Link Roundups
                  </h1>

                  <p className="mt-10 text-[18px] font-RobatoCondesed leading-loose text-justify">
                    A useful method for showcasing the most relevant and
                    up-to-date content from multiple sources is through link
                    roundups. These carefully selected lists give readers access
                    to a wide variety of information from a single source. Since
                    they may help people keep informed without having to search
                    the internet, link roundups have grown in popularity in
                    recent years. This is because they can save individuals
                    time.
                  </p>
                </div>
              </div>
              <p className="mt-10 text-[18px] font-RobatoCondesed text-justify leading-loose">
                Link roundups benefit website owners as well because they
                contribute to the development of backlinks, which raises the
                website's search engine position. Google and other search
                engines will give a website a higher ranking if there are more
                high-quality links pointing to it. Websites can draw in more
                visitors and potential clients by creating quality content that
                is featured in link roundup sections.
              </p>
            </div>
          </section>
          {/* ------Competitor Backlink Research--- */}
          <section>
            <div className="px-16 mt-10 max-md:px-6">
              <div className="flex max-lg:flex-col">
                <div className="w-[50%] max-lg:w-full">
                  <h1 className="text-[35px] uppercase font-RobatoCondesed text-gradient">
                    Competitor Backlink Research
                  </h1>
                  <p className="text-[18px] font-RobatoCondesed leading-loose text-justify">
                    Researching competitor backlinks is a key part of any SEO
                    strategy that works. You may learn a lot about your
                    competitors' SEO strategies and identify potential business
                    prospects for your website by looking at their backlink
                    profiles. This approach entails identifying the
                    highest-ranking websites within your sector and analyzing
                    the backlink profiles of those websites to determine the
                    websites that connect to them.
                  </p>
                </div>
                <div className="w-[50%] max-lg:w-full">
                  <center>
                    {" "}
                    <img className="h-96" src={BackLinkImage} alt="" />{" "}
                  </center>
                </div>
              </div>
              <p className="text-[18px] font-RobatoCondesed leading-loose text-justify">
                Working with competitor backlink research has several benefits,
                one of which is that it keeps you ahead of the search engine
                optimization curve. You may find reputable sites to connect to
                your website by looking at who is linking to its competitors.
                Furthermore, this study might assist you in identifying
                link-building tactics that your industry may have overlooked or
                neglected.
              </p>
            </div>
          </section>
          {/* ------Google Alert And Mentions--- */}
          <section>
            <div className="px-16 mt-10 max-md:px-6">
              <div className="flex max-lg:flex-col">
                <div className="w-[50%] max-lg:w-full">
                  <center>
                    {" "}
                    <img className="h-96" src={AlertImage} alt="" />{" "}
                  </center>
                </div>
                <div className="w-[50%] max-lg:w-full">
                  <h1 className="text-[35px] uppercase font-RobatoCondesed text-gradient">
                    Google Alert And Mentions
                  </h1>
                  <p className="text-[18px] font-RobatoCondesed leading-loose text-justify mt-10">
                    Businesses may keep informed about their brand, competitors,
                    and niche trends by utilizing Google Alerts and Mentions,
                    two effective tools. Users can monitor the web for specific
                    terms or phrases by using Google Alerts, a free service. To
                    be informed whenever certain terms surface online, users can
                    set up alerts for their brand name, goods, or services.
                    Businesses trying to monitor mentions of their brand on
                    multiple websites may find this tool particularly helpful.
                  </p>
                </div>
              </div>
              <p className="text-[18px] font-RobatoCondesed leading-loose text-justify">
                Conversely, social media management services such as Hootsuite
                and Sprout Social offer Mentions as a premium service. It
                enables businesses to track real-time Internet conversations
                about their brand. Users can utilize Mentions to receive
                notifications anytime their brand is mentioned on social media
                platforms such as Instagram or Twitter. For businesses trying to
                interact with clients who are discussing their services or goods
                online, this tool is perfect.
              </p>
            </div>
          </section>
        </section>
        {/* ------Competitor Analysis ----- */}
        <section>
          <div className="mt-20">
            <h1 className="text-center uppercase font-RobatoCondesed text-[35px]">
              Competitor Analysis
            </h1>
            <p className="mt-5 font-RobatoCondesed text-center">
              At Honey Iconics, we study the following aspects to make your
              website rank better than your competitors.
            </p>
          </div>
          <section className="py-6">
            <div className=" flex flex-col justify-center px-5 md:px-16 mx-auto ">
              <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                <div className=" shadow-lg shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/05/undraw_fast_loading_re_8oi3.png"
                      alt=""
                    />
                  </center>

                  <div>
                    <h1 className="text-center font-bold font-RobatoCondesed uppercase">
                      Domain Age
                    </h1>
                    <p className="mt-5 text-[18px] font-RobatoCondesed flex-grow px-5 text-justify mb-10">
                      Domain age reflects the longevity of a website, with older
                      domains often earning more trust. Established domains may
                      have a stronger online presence, benefiting from years of
                      content development and credibility in their respective
                      industries.
                    </p>
                  </div>
                </div>
                {/* =========== */}{" "}
                <div className=" shadow-lg flex flex-col shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/06/Keyword-Research-2048x1366.jpg"
                      alt=""
                    />
                  </center>
                  <div className="flex-grow h-full">
                    <h1 className="text-center font-bold font-RobatoCondesed uppercase">
                      Keyword Length
                    </h1>
                    <p className="mt-5 text-[18px]  font-RobatoCondesed  px-5 text-justify mb-10">
                      Optimal keyword length is crucial for SEO, with concise
                      and relevant keywords enhancing search engine visibility.
                      Short, targeted keywords increase the likelihood of
                      attracting qualified traffic to your website.
                    </p>
                  </div>
                </div>
                {/* ============= */}
                <div className=" shadow-md shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/06/undraw_Domain_names_re_0uun.png"
                      alt=""
                    />
                  </center>
                  <div>
                    <h1 className="text-center font-RobatoCondesed uppercase font-bold">
                      Backlink Gap
                    </h1>
                    <p className="mt-5 font-RobatoCondesed text-[18px] px-5 text-justify mb-10">
                      Analyzing the backlink gap helps identify the disparity
                      between your site and competitors. Bridging this gap by
                      acquiring quality backlinks can boost your website's
                      authority and improve search engine rankings.
                    </p>
                  </div>
                </div>
                {/* ============== */}
                <div className=" shadow-lg shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/09/Content-Writing.png"
                      alt=""
                    />
                  </center>

                  <div>
                    <h1 className="text-center font-RobatoCondesed uppercase font-bold">
                      Content Quality
                    </h1>
                    <p className="mt-5 text-[18px] font-RobatoCondesed px-5 text-justify mb-10">
                      High-quality content is the backbone of a successful
                      website, engaging users and satisfying search engine
                      algorithms. Well-crafted, informative content not only
                      attracts visitors but also contributes to improved SEO
                      performance.
                    </p>
                  </div>
                </div>
                {/* ============== */}
                <div className=" shadow-lg shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/06/Higher-Domain-Authority-copy.png"
                      alt=""
                    />
                  </center>
                  <div>
                    <h1 className="text-center font-bold font-RobatoCondesed uppercase">
                      Website Domain Authority
                    </h1>
                    <p className="mt-5 font-RobatoCondesed flex-grow h-full text-[18px] px-5 text-justify mb-10">
                      Domain authority gauges the overall strength of a website
                      in search engine results. A higher domain authority
                      indicates a site's credibility, making it more likely to
                      rank well for relevant keywords and attract organic
                      traffic.
                    </p>
                  </div>
                </div>
                <div className=" shadow-lg shadow-gray-400 rounded-2xl">
                  <center>
                    <img
                      className="h-52"
                      src="https://www.rankontechnologies.com/wp-content/uploads/2022/06/Quantity-Of-Traffic-copy.png"
                      alt=""
                    />
                  </center>
                  <div>
                    <h1 className="text-center font-bold font-RobatoCondesed uppercase">
                      Common Keywords
                    </h1>
                    <p className="mt-5 font-RobatoCondesed h-full text-[18px] px-5 text-justify mb-10">
                      Identifying common keywords in your niche is essential for
                      effective SEO. Incorporating these keywords strategically
                      into your content helps align your website with user
                      search intent, increasing visibility and relevance in
                      search engine results.
                    </p>
                  </div>
                </div>
                {/* ===== */}
              </div>
            </div>
          </section>
        </section>
        {/* ------Why You Must Hire Professionals For Off-Page SEO? ----- */}
        <section>
          <div className="font-RobatoCondesed">
            <div className="px-16 max-md:px-6">
              <h1 className="text-[35px] uppercase font-RobatoCondesed">
                Why is hiring experts important for Off-Page SEO?
              </h1>
              <p className="mt-10 text-[18px] font-RobatoCondesed leading-loose">
                Off-page SEO requires expertise, time, and resources. Hiring
                professionals for Off-Page SEO can benefit your business in the
                following ways:
              </p>

              <div className="mt-10 ">
                <div>
                  <ul className="">
                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] leading-loose">
                        Professionals have the experience and knowledge to
                        execute Off-Page SEO techniques effectively and
                        efficiently.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] leading-loose">
                        As a professional SEO company, we have access to
                        advanced tools and resources that can help them perform
                        Off-Page SEO at a higher level.
                      </span>
                    </li>

                    <li className="flex ">
                      <img className="h-6 mt-3" src={Tick} alt="loading" />
                      <span className="pb-5 ml-2 text-[18px] leading-loose">
                        Outsourcing Off-Page SEO can free up your time and
                        resources to focus on other aspects of your business.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ------Why RankON Technologies For Best Off-Page SEO Services?----- */}
        <section>
          <div className="px-16 py-20 mt-10 bg-gray-100 max-md:px-6">
            <div className="font-RobatoCondesed">
              <h1 className="text-[35px] uppercase">
                What Makes the
                <span className="ml-3 mr-3 font-serif text-gradient">
                  Honey Iconics
                </span>
                Best Off-Page SEO Service Provider ?
              </h1>
              <p className="mt-10 text-justify text-[18px] leading-loose">
                Providing small businesses with excellent on-page and off-page
                SEO services is Honey Iconics' area of expertise as a leading
                digital marketing agency. The company, which has years of
                experience in the field, is known for helping companies reach
                their internet marketing objectives and producing excellent
                results.
              </p>

              <p className="mt-10 text-justify text-[18px] leading-loose">
                Our group of SEO specialists is one of the factors that makes
                Honey Iconics the best choice for SEO services. Our staff
                consists of seasoned SEO experts with extensive training who
                genuinely love what they do. They are continually abreast of the
                newest trends and industry best practices and possess an
                in-depth understanding of how search engines operate.
              </p>

              <p className="mt-10 text-justify text-[18px] leading-loose">
                Honey Iconics stands itself from other SEO services due to its
                unwavering commitment to customer satisfaction. Our SEO company
                understands that every company is different, which is why each
                project requires a customized approach. Kindly review our full
                offerings for off-page SEO and let us know which one best fits
                your needs.
              </p>
            </div>
          </div>
        </section>
        {/* ------Frequently Asked Questions About Off-Page SEO Services----- */}
        <section>
          <div className="px-16 mt-10 max-md:px-6 font-RobatoCondesed">
            <div>
              <h1 className="text-[35px] uppercase">
                <span className="ml-3 mr-3 font-serif text-gradient">
                  Common Questions About Off-Page SEO Services
                </span>
                About Off-Page SEO Services
              </h1>
            </div>
          </div>
        </section>
        <section>
          <div className="px-16 mt-10 mb-10 max-md:px-6">
            <div className="space-y-4">
              <details className="w-full text-white rounded-lg font-RobatoCondesed shadow-xl bg-slate-900 ">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What is Off-Page SEO?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Off-page SEO refers to activities and strategies that are
                  implemented outside of your website to improve its visibility
                  and credibility. This includes building backlinks, social
                  media marketing, influencer outreach, and other external
                  factors that impact your site's search engine rankings.
                </p>
              </details>
              <details className="w-full text-white rounded-lg font-RobatoCondesed shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  Why is Off-Page SEO Important?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Off-page SEO is important because search engines consider
                  factors beyond your website's content and structure when
                  determining its relevance and authority. Backlinks from
                  reputable sites, social signals, and other off-page elements
                  contribute to search engines' understanding of your site's
                  credibility and popularity.
                </p>
              </details>
              <details className="w-full font-RobatoCondesed text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  What are Backlinks?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Backlinks are links from other websites that point to your
                  site. They are a crucial off-page SEO factor because search
                  engines interpret them as a vote of confidence in your
                  content. Quality backlinks from authoritative sites can
                  significantly improve your site's search engine rankings.
                </p>
              </details>
              <details className="w-full font-RobatoCondesed text-white rounded-lg shadow-xl bg-slate-900">
                <summary className="px-4 py-6 duration-100 ease-in-out focus:outline-none focus-visible:ri">
                  How Can I Build Quality Backlinks?
                </summary>
                <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 dark:text-gray-400">
                  Building quality backlinks requires a strategic approach.
                  Focus on creating valuable, shareable content that naturally
                  attracts links. Outreach to other websites in your niche for
                  guest posting, collaboration, or partnerships can also be
                  effective. Avoid spammy link-building practices, as they can
                  harm your site's reputation.
                </p>
              </details>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Offpageseo;
