import React, { useEffect, useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { AiOutlineTwitter } from "react-icons/ai";
import { Helmet } from "react-helmet";
const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const emailAddress = "gmsudhan3@gmail.com"; // Replace with the recipient's email address
  const subject = "New message from your website Anandtham"; // Change the email subject as needed

  useEffect(() => {
    if (submitted && name !== "" && phone !== "" && message !== "") {
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject
      )}&body=Name: ${name}%0D%0APhone: ${phone}%0D%0AMessage: ${message}`;
      window.location.href = mailtoLink;
      setSubmitted(false); // Reset the form submission state
    }
  }, [submitted, name, phone, message]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true); // Triggers the useEffect to send the email
  };

  return (
    <>
      <div>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Customer Services </title>
          <meta name="description" content="where innovation meets passion. Learn about our team, mission, and the commitment driving us to redefine digital excellence. Discover how we turn visions into reality, one project at a time. Join us in shaping the future of a digital world where creativity knows no bounds. " />
          <link
            rel="canonical"
            href="https://honeyiconics.com/home
"
          />
        </Helmet>
      </div>
        <section className="wrapper-two h-[300px] max-md:h-auto">
          <div id="stars1"></div>
          <div id="stars2"></div>
          <div id="stars3"></div>
          <div className="h-auto mt-5 mb-5 ">
            <div className="row">
              <div className="text-center ">
                <h3 className="animate-charcter text-[150px] max-sm:text-[50px]">
                  Contact Us
                </h3>
                <center></center>
              </div>
            </div>
          </div>
        </section>
        {/* -------------- */}
        <section>
          <div className="flex h-auto px-16 mt-10 mb-10 max-lg:flex-col max-sm:px-6">
            <div className="w-[50%] max-lg:w-full">
              <h2 className="text-[20px] font-RobatoCondesed  text-gradient font-bold uppercase  tracking-widest ">
                Contact Us
              </h2>
              <h2 className="mt-2 text-[32px] tracking-wider font-bold font-RobatoCondesed">
                <span className="text-gradient"> GET IN TOUCH </span> WITH US
              </h2>
              <p className="mt-3 text-[18px]  text-justify font-RobatoCondesed">
                Crafting your dream website shouldn't feel like scaling Mount
                Everest in flip-flops. At FindWay Digital, we believe digital
                experiences should be built with ease and enjoyment, not
                frustration and tech fatigue. That's why we've honed a process
                that guides you step-by-step from pixelation purgatory to online
                paradise. <br />
                <br />
                No more code conundrums-Crystal-clear communication -
                Transforming vision into reality - Stress-free launch and beyond
              </p>
              <h2 className="mt-3 font-RobatoCondesed text-[28px] font-bold">
                OUR SOCIAL MEDIA
              </h2>
              <p className="mt-2 text-[16px] font-RobatoCondesed">
                Do you want Custom Project? Contact and Follow us also in our
                Social Media Page
              </p>

              <div className="mt-5 ">
                <ul className="flex gap-10">
                  <li>
                    <FaFacebookF className="text-gray-600 text-[40px]" />
                  </li>
                  <li>
                    <IoLogoInstagram className="text-gray-600 text-[40px]" />
                  </li>
                  <li>
                    <AiOutlineTwitter className="text-gray-600 text-[45px]" />
                  </li>
                </ul>
              </div>
            </div>
    
              <div className="w-[50%] max-lg:w-full max-lg:mt-10 h-auto  ">
              <form onSubmit={handleFormSubmit} className="">
                <div className="">
                  <h2 className="text-center font-RobatoCondesed font-normal text-[42px] uppercase">
                    contact Form
                  </h2>

                  <div className="pl-10 max-lg:pl-0">
                    <div>
                      <h2 className="mb-2 font-RobatoCondesed text-[18px] tracking-wider">
                        Name
                      </h2>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="border-[1px] pl-2 py-2 border-black w-full "
                        placeholder="Name"
                      />
                    </div>

                    <div>
                      <h2 className="mb-2 mt-5 font-RobatoCondesed text-[18px] tracking-wider">
                        Number
                      </h2>
                      <input
                         value={phone}
                         onChange={(e) => setPhone(e.target.value)}
                        type="text"
                        className="border-[1px] pl-2 py-2 border-black w-full "
                        placeholder="Number"
                      />
                    </div>

                    <div>
                      <h2 className="mb-2 mt-5 font-RobatoCondesed text-[18px] tracking-wider">
                        Message
                      </h2>
                      <textarea
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        cols="30"
                        placeholder="Message Here"
                        rows="5"
                        className="pt-3 pl-3 w-full border-[1px] border-gray-600 "
                      ></textarea>
                      <button class="btn-hover color-2 z-50 pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%] ">
                        <span className=" text-[18px] font-medium">Submit</span>
                      </button>
                    </div>
                  </div>
                </div>
                </form>
              </div>
           
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
