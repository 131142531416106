import "./App.css";

import "./pages/all-seo/seo.css";

import { Route, Routes } from "react-router-dom";

import ScroolToTop from "./components/Scrollings/auto-scroll-on-nav";
// import NavrBar from "./components/home/navBar/navbar";

import Home from "./pages/home";
import AboutUs from "./pages/about-us";
import OurWork from "./pages/our-workflow";
import Blog from "./pages/blog";
import Contact from "./pages/contact";

import DigitalMarketing from "./pages/digital-marketing-in-coimbatore";
import LocalSeo from "./pages/all-seo/local-seo";
import OnPageSeo from "./pages/all-seo/onpage-seo";
import OffPageSeo from "./pages/all-seo/offpage-seo";
import TechnicalPageseo from "./pages/all-seo/technical-seo";
import SoftwareDevelopment from "./pages/custom-software-development-company-coimbatore";
import WebDevelopment from "./pages/website-designing-company-coimbatore";
import AppDevelopment from "./pages/mobile-app-developers-coimbatore";
import Headerone from "./components/NavBars/header-one/headerone";
import PriceandPackage from "./pages/priceandpackage";
import Footer from "./components/footer/footer";
import Navs from "./components/NavBars/Main-Nav-Bar/Nav-one";

function App() {
  return (
    <>
      <div className=" md:top-0 md:z-50 md:sticky">
        {" "}
        <Headerone />
        <Navs />
      </div>

      <Routes>
        <Route
          path="/"
          element={
            <>
              <ScroolToTop />
              <Home />
            </>
          }
        />
        <Route
          path="/about-us"
          element={
            <>
              <ScroolToTop />
              <AboutUs />{" "}
            </>
          }
        />
        <Route
          path="/digital-marketing-in-madurai"
          element={
            <>
              <ScroolToTop />
              <DigitalMarketing />
            </>
          }
        />
        <Route
          path="/local-page-seo"
          element={
            <>
              {" "}
              <ScroolToTop />
              <LocalSeo />
            </>
          }
        />
        <Route
          path="/on-page-seo"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OnPageSeo />
            </>
          }
        />
        <Route
          path="/off-page-seo"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OffPageSeo />
            </>
          }
        />
        <Route
          path="/technical-seo"
          element={
            <>
              {" "}
              <ScroolToTop />
              <TechnicalPageseo />
            </>
          }
        />
        <Route
          path="/custom-software-development-company-madurai"
          element={
            <>
              {" "}
              <ScroolToTop />
              <SoftwareDevelopment />
            </>
          }
        />
        <Route
          path="/website-designing-company-madurai"
          element={
            <>
              {" "}
              <ScroolToTop />
              <WebDevelopment />
            </>
          }
        />
        <Route
          path="/mobile-app-developers-madurai"
          element={
            <>
              {" "}
              <ScroolToTop />
              <AppDevelopment />
            </>
          }
        />
        <Route
          path="/Price-package"
          element={
            <>
              {" "}
              <ScroolToTop />
              <PriceandPackage />
            </>
          }
        />
        <Route
          path="/our-work"
          element={
            <>
              {" "}
              <ScroolToTop />
              <OurWork />
            </>
          }
        />
        <Route
          path="/blog"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Blog />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              {" "}
              <ScroolToTop />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
