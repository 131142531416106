import React, { useEffect, useState } from "react";
import Imageone from "../images/Home/asto.png";
import Responsive from "../images/Home/Image-Sec-Two.jpg";
import Marketing from "../images/digital-campaign.png";
import Seo from "../images/seo.png";
import SocialMedia from "../images/social-media.png";
import SoftwareDevelopment from "../images/software-development.png";
import Coding from "../images/coding.png";
import AppDevelopment from "../images/app-development.png";
import Checkbox from "../images/checkbox (1).png";
import priviw from "../images/4576-removebg-preview.png";
import Imagehang from "../images/31640886_3084-removebg-preview.png";
import Google from "../images/google.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Image from "../images/12.jpeg";
import ReactImage from "../images/tech-images/tech/fed/react.png";
import AngulerImage from "../images/tech-images/tech/fed/angular.png";
import VueImage from "../images/tech-images/tech/fed/vue.png";
import HtmlImage from "../images/tech-images/tech/fed/html.png";
import Cssimage from "../images/tech-images/tech/fed/css.png";
import JavascriptImage from "../images/tech-images/tech/fed/js.png";
import JqueryImage from "../images/tech-images/tech/fed/jquery.png";
import BootStrapImage from "../images/tech-images/tech/fed/bootstrap.png";
import NpmImage from "../images/tech-images/tech/fed/npm.png";

import WooCommerceImage from "../images/tech-images/tech/CMS/woocommerce.png";
import WordpressImage from "../images/tech-images/tech/CMS/wordpress.png";

import Laravel from "../images/tech-images/tech/data/laravel.png";
import Mongodb from "../images/tech-images/tech/data/mongodb.png";
import Node from "../images/tech-images/tech/data/node.png";
import PHPImage from "../images/tech-images/tech/data/php.png";
import Python from "../images/tech-images/tech/data/python.png";

import Mobile from "../images/tech-images/tech/mobile/flutter.png";
import Andriod from "../images/tech-images/tech/mobile/android.png";

import { Helmet } from "react-helmet";
import { lazy } from "react";

import PortImageone from "../images/workflow/WhatsApp Image 2023-12-21 at 04.50.18_0f7f5bce.jpg";
import PortImageFive from "../images/workflow/WhatsApp Image 2023-12-21 at 04.50.19_80197c52.jpg";

import FirstImage from "../images/workflow/devdigitallandsurveyor.com_index.html.png";
import SecondImage from "../images/workflow/nmsindustrialprojects.com_ (2).png";
import PortImageCateringOne from "../images/workflow/bestcaterersincoimbatore.com_(iPad Mini).png";
import PortImageCateringTwo from "../images/workflow/sribalajicatering.in_ (1).png";
import PortImageCateringThree from "../images/workflow/jaiganeshcateringservices.com_.png";

import PortImageInteriorOne from "../images/workflow/inddecoreinteriors.com_.png";

import PortToursOne from "../images/workflow/abtoursandtravels.in_.png";
import { Link } from "react-router-dom";
const Home = () => {


  function start() {
    //Helpers
    function lineToAngle(x1, y1, length, radians) {
      var x2 = x1 + length * Math.cos(radians),
        y2 = y1 + length * Math.sin(radians);
      return { x: x2, y: y2 };
    }

    function randomRange(min, max) {
      return min + Math.random() * (max - min);
    }

    function degreesToRads(degrees) {
      return (degrees / 180) * Math.PI;
    }

    //Particle
    var particle = {
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      radius: 0,

      create: function (x, y, speed, direction) {
        var obj = Object.create(this);
        obj.x = x;
        obj.y = y;
        obj.vx = Math.cos(direction) * speed;
        obj.vy = Math.sin(direction) * speed;
        return obj;
      },

      getSpeed: function () {
        return Math.sqrt(this.vx * this.vx + this.vy * this.vy);
      },

      setSpeed: function (speed) {
        var heading = this.getHeading();
        this.vx = Math.cos(heading) * speed;
        this.vy = Math.sin(heading) * speed;
      },

      getHeading: function () {
        return Math.atan2(this.vy, this.vx);
      },

      setHeading: function (heading) {
        var speed = this.getSpeed();
        this.vx = Math.cos(heading) * speed;
        this.vy = Math.sin(heading) * speed;
      },

      update: function () {
        this.x += this.vx;
        this.y += this.vy;
      },
    };

    //Canvas and settings
    var canvas = document.getElementById("canvas"),
      context = canvas.getContext("2d"),
      width = (canvas.width = window.innerWidth),
      height = (canvas.height = window.innerHeight),
      stars = [],
      shootingStars = [],
      // -------
      layers = [
        { speed: 2.15, scale: 0.2, count: 320 },
        { speed: 0.015, scale: 0.5, count: 50 },
        { speed: 2.5, scale: 0.75, count: 30 },
      ],
      // --------
      starsAngle = 145,
      shootingStarSpeed = {
        min: 15,
        max: 20,
      },
      shootingStarOpacityDelta = 0.01,
      trailLengthDelta = 0.01,
      shootingStarEmittingInterval = 2000,
      shootingStarLifeTime = 500,
      maxTrailLength = 300,
      starBaseRadius = 2,
      shootingStarRadius = 3,
      paused = false;

    //Create all stars
    for (var j = 0; j < layers.length; j += 1) {
      var layer = layers[j];
      for (var i = 0; i < layer.count; i += 1) {
        var star = particle.create(
          randomRange(0, width),
          randomRange(0, height),
          0,
          0
        );
        star.radius = starBaseRadius * layer.scale;
        star.setSpeed(layer.speed);
        star.setHeading(degreesToRads(starsAngle));
        stars.push(star);
      }
    }

    function createShootingStar() {
      var shootingStar = particle.create(
        randomRange(width / 2, width),
        randomRange(0, height / 2),
        0,
        0
      );
      shootingStar.setSpeed(
        randomRange(shootingStarSpeed.min, shootingStarSpeed.max)
      );
      shootingStar.setHeading(degreesToRads(starsAngle));
      shootingStar.radius = shootingStarRadius;
      shootingStar.opacity = 0;
      shootingStar.trailLengthDelta = 0;
      shootingStar.isSpawning = true;
      shootingStar.isDying = false;
      shootingStars.push(shootingStar);
    }

    function killShootingStar(shootingStar) {
      setTimeout(function () {
        shootingStar.isDying = true;
      }, shootingStarLifeTime);
    }
    // --------------------------------------------BG----Color----
    function update() {
      if (!paused) {
        context.clearRect(0, 0, width, height);
        context.fillStyle = "#000";
        context.fillRect(0, 0, width, height);
        context.fill();
        // --------------------------------------------BG----Color----
        for (var i = 0; i < stars.length; i += 1) {
          var star = stars[i];
          star.update();
          drawStar(star);
          if (star.x > width) {
            star.x = 0;
          }
          if (star.x < 0) {
            star.x = width;
          }
          if (star.y > height) {
            star.y = 0;
          }
          if (star.y < 0) {
            star.y = height;
          }
        }

        for (i = 0; i < shootingStars.length; i += 1) {
          var shootingStar = shootingStars[i];
          if (shootingStar.isSpawning) {
            shootingStar.opacity += shootingStarOpacityDelta;
            if (shootingStar.opacity >= 1.0) {
              shootingStar.isSpawning = false;
              killShootingStar(shootingStar);
            }
          }
          if (shootingStar.isDying) {
            shootingStar.opacity -= shootingStarOpacityDelta;
            if (shootingStar.opacity <= 0.0) {
              shootingStar.isDying = false;
              shootingStar.isDead = true;
            }
          }
          shootingStar.trailLengthDelta += trailLengthDelta;

          shootingStar.update();
          if (shootingStar.opacity > 0.0) {
            drawShootingStar(shootingStar);
          }
        }

        //Delete dead shooting shootingStars
        for (i = shootingStars.length - 1; i >= 0; i--) {
          if (shootingStars[i].isDead) {
            shootingStars.splice(i, 1);
          }
        }
      }
      requestAnimationFrame(update);
    }
    //--------------------------------------------------
    function drawStar(star) {
      context.fillStyle = "#ffff";
      context.beginPath();
      context.arc(star.x, star.y, star.radius, 0, Math.PI * 2, false);
      context.fill();
    }
    // ---------------------------------------------------
    function drawShootingStar(p) {
      var x = p.x,
        y = p.y,
        currentTrailLength = maxTrailLength * p.trailLengthDelta,
        pos = lineToAngle(x, y, -currentTrailLength, p.getHeading());

      context.fillStyle = "rgba(255, 255, 255, " + p.opacity + ")";
      // context.beginPath();
      // context.arc(x, y, p.radius, 0, Math.PI * 2, false);
      // context.fill();
      var starLength = 5;
      context.beginPath();
      context.moveTo(x - 1, y + 1);

      context.lineTo(x, y + starLength);
      context.lineTo(x + 1, y + 1);

      context.lineTo(x + starLength, y);
      context.lineTo(x + 1, y - 1);

      context.lineTo(x, y + 1);
      context.lineTo(x, y - starLength);

      context.lineTo(x - 1, y - 1);
      context.lineTo(x - starLength, y);

      context.lineTo(x - 1, y + 1);
      context.lineTo(x - starLength, y);

      context.closePath();
      context.fill();

      //trail
      context.fillStyle = "rgba(255, 221, 157, " + p.opacity + ")";
      context.beginPath();
      context.moveTo(x - 1, y - 1);
      context.lineTo(pos.x, pos.y);
      context.lineTo(x + 1, y + 1);
      context.closePath();
      context.fill();
    }

    //Run
    update();

    //Shooting stars
    setInterval(function () {
      if (paused) return;
      createShootingStar();
    }, shootingStarEmittingInterval);

    window.onfocus = function () {
      paused = false;
    };

    window.onblur = function () {
      paused = true;
    };
  }

  const [state, setState] = useState(1);
  const action = (index) => {
    setState(index);
    console.log(index);
  };

  const options = {
    loop: true,
    margin: 10,
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 2000,

    responsive: {
      0: {
        items: 1,
        nav: true,
        loop: true,
      },

      800: {
        items: 2,
        nav: true,
        loop: true,
      },
      1000: {
        items: 3,
        nav: true,
        loop: true,
      },
    },
  };

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const emailAddress = "gmsudhan3@gmail.com"; // Replace with the recipient's email address
  const subject = "New message from your website Anandtham"; // Change the email subject as needed

  useEffect(() => {
    if (submitted && name !== "" && phone !== "" && message !== "") {
      const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
        subject
      )}&body=Name: ${name}%0D%0APhone: ${phone}%0D%0AMessage: ${message}`;
      window.location.href = mailtoLink;
      setSubmitted(false); // Reset the form submission state
    }
  }, [submitted, name, phone, message]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true); // Triggers the useEffect to send the email
  };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            FindWay Digital - Software Development Company in Madurai{" "}
          </title>
          <meta
            name="description"
            content="FindWay Digital is a Premier software development company in Madurai, delivering top-notch solutions for businesses."
          />
          <link
            rel="canonical"
            href="https://honeyiconics.com/home
"
          />
        </Helmet>
      </div>
      <div className="">
        <div className=" w-full lg:h-[600px] md:h-[600px] lg:h-[750px] xl:h-[700] xxl:h-[700] relative ">
          {/* ----image---- Bacground-----*/}
          <div
            className="relative justify-between image lg:flex md:flex"
            onLoad={lazy}
          >
            {/* --------------Asreonot---iamge-------- */}
            <div className="relative w-full h-full one ">
             <div>
              
                <canvas
                  id="canvas"
                  width="100%"
                  height="100%"
                  className=" w-[80%] h-[500px] new max-md:w-[70%] max-sm:w-full"
                ></canvas>
              </div> 
             {/* <img
                className="object-cover h-full w-[100%] call  max-md:hidden"
                src={Imageone}
                alt="loading"
                onLoad={lazy}
              /> */}
            </div>

            {/* --------------------------- CONTENT ----------------- */}
            <div className="w-full h-full  px-5 lg:pt-[80px] md:pt-[80px] max-md:absolute max-md:top-10 max-md:z-50">
              <h2 className="text-white font-normal md:text-[34px] md:font-sevenhundered lg:text-[40px]  lg:font-sevenhundered max-md:text-[34px]">
                Turn Your Digital Dreams into Reality with FindWay Digital
              </h2>

              <p className="mb-10 font-normal text-white font-RobatoCondesed mt-7 ">
                FindWay Digital is your trusted ally in turning digital dreams
                into tangible results. With our expertise in innovative designs,
                tailored solutions, and ongoing support, we're here to help you
                thrive in the digital landscape. Reach out to us today and let's
                embark on your path to digital success.
              </p>

              {/* ----Button----- */}
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
            className="absolute -bottom-2"
          >
            <path
              fill="white"
              fill-opacity="1"
              d="M0,32L30,48C60,64,120,96,180,138.7C240,181,300,235,360,266.7C420,299,480,309,540,266.7C600,224,660,128,720,122.7C780,117,840,203,900,197.3C960,192,1020,96,1080,74.7C1140,53,1200,107,1260,112C1320,117,1380,75,1410,53.3L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
            ></path>
          </svg>
        </div>
        {/* ------------------------------------ Section - One ---------------------- */}
        <section>
          <div>
            <div className="px-10 bg-white max-sm:px-4 ">
              <div className="flex max-md:flex-col">
                <div className="w-full h-auto px-5 pt-5">
                  <img
                    className="object-cover w-full h-full"
                    src={Responsive}
                    alt="loading"
                  />
                </div>
                <div className="w-full h-auto ">
                  <h2 className="text-[35px] uppercase font-RobatoCondesed max-sm:text-[35px] max-md:text-center  text-slate-900">
                    Boost Your Business with Us
                  </h2>
                  <h2 className="text-[25px] font-serif text-gradient uppercase max-md:text-[23px] max-sm:text-[18px] max-lg:text-[23px] max-md:text-center  ">
                    Leading Web Development Company in Madurai
                  </h2>

                  <h3 className="pr-5 font-RobatoCondesed text-[18px] mt-10 text-justify text-slate-900">
                    At FindWay Digital in Madurai, we are your dedicated
                    partner for all-encompassing digital solutions. Our
                    comprehensive services include mobile app development,
                    website design and development, digital marketing, and
                    software development. With a keen focus on delivering
                    innovative and customized solutions, we cater to your unique
                    business needs.
                    <br /> <br />
                    As the best website design company in Madurai, we're
                    dedicated to helping you achieve all of your digital goals.
                    Our talented team of designers and developers, located in
                    the center of Madurai, specializes in building websites that
                    always surpass your expectations. We prioritize working
                    together to make sure that each project is driven by your
                    unique ideas. We offer advanced and creative solutions by
                    staying ahead of the most recent developments in the
                    business. Our work is based on reliability and quality, and
                    your success online is our main goal.
                  </h3>
                  <Link to={"about-us"}>
                    {" "}
                    <button class="btn-hover color-2 mt-10 pt-2 pb-2 pl-8 pr-8">
                      Read more
                    </button>
                  </Link>
                </div>{" "}
              </div>
            </div>
            <p></p>
          </div>
        </section>
        {/* ------------------------------------ Section - Services ---------------------- */}

        <section>
          <div className="relative w-full pt-56 pb-56 mt-5 mb-32 text-white max-sm:pt-28 bg-slate-900">
            {/* Header */}

            <div className="items-center w-full h-auto pt-3 max-md:pl-0 ">
              <h1 className=" font-RobatoCondesed uppercase   text-[45px] pt-20 text-white text-center">
                Our <span className="text-gradient">SupreEm </span> Services
              </h1>

              <h2 className="px-32 pt-5 font-RobatoCondesed text-[18px]  text-lg text-center text-white max-md:text-justify max-md:px-5">
                Our goal at FindWay Digital is to provide services that enhance
                quality in the digital era. Being a leader in our industry, we
                provide a variety of solutions that enable your organization to
                succeed, ranging from software development to digital marketing.
                Our team of professionals is committed to building your brand
                online, interacting with your audience, and creating
                opportunities for your company to expand.
              </h2>
            </div>
            <section className="py-6 mb-36 max-sm:mb-0 dark:bg-gray-800">
              <div className="flex flex-col justify-center p-4 mx-auto ">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                  <div className="w-full h-[100%] flex flex-col  mt-8 text-white bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img
                          className="w-16 h-15"
                          src={Marketing}
                          alt="loading"
                        />
                      </div>

                      <h2 className="tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        Digital Marketing
                      </h2>
                    </div>
                    <div className="flex-grow h-full p-5 pt-0 text-center  text-[18px] ">
                      <h3 className="text-justify  font-RobatoCondesed text-[18px]">
                        Use the power of digital marketing to grow your
                        business. Our tailored strategies are designed to boost
                        brand visibility, drive targeted traffic, and help you
                        achieve your marketing goals. Experience the
                        transformative impact of digital marketing with us.
                      </h3>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link to={"digital-marketing-in-madurai"}>
                        {" "}
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                  {/* ---------------------- */}
                  <div className="flex-col flex   h-[100%] mt-8 text-white bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img className="w-16 h-15" src={Seo} alt="loading" />
                      </div>

                      <span className="tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        SEO(Search Engin Optimization){" "}
                      </span>
                    </div>
                    <div className="flex-grow h-full p-5 pt-0 text-center  text-[18px] ">
                      <h3 className="text-justify text-[18px]   font-RobatoCondesed">
                        SEO drives online success by fine-tuning your digital
                        presence for easy discovery by the target audience. It's
                        a crucial tool for climbing search engine rankings,
                        boosting website traffic, and enhancing brand visibility
                        in the competitive online landscape.
                      </h3>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link to={"local-page-seo"}>
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>
                      </Link>
                    </div>
                  </div>
                  {/* --------------------------------------- */}
                  <div className="w-full h-[100%] flex flex-col mt-8 text-white bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800 ">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img
                          className="w-16 h-15"
                          src={SocialMedia}
                          alt="loading"
                        />
                      </div>

                      <span className=" tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        SOCIAL MEDIA (Marketing)
                      </span>
                    </div>
                    <div className="flex-grow h-full p-5 pt-0 text-center  text-[18px] ">
                      <h3 className="text-justify text-[18px] font-RobatoCondesed">
                        Social media marketing is a driving force for businesses
                        in the digital age. It enhances brand visibility,
                        fosters customer engagement, and generates valuable
                        leads. Social media marketing can significantly
                        contribute to your business's growth and success
                      </h3>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link to={"local-page-seo"}>
                        {" "}
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                  {/* ======================== */}
                  <div className="w-full h-[100%] flex flex-col mt-8 text-white bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img
                          className="w-16 h-15"
                          src={SoftwareDevelopment}
                          alt="loading"
                        />
                      </div>

                      <span className=" tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        SOFTWARE DEVELOPMENT
                      </span>
                    </div>
                    <div className="flex-grow h-full p-5 pt-0 text-center text-[18px] ">
                      <p className="text-justify text-[18px] font-RobatoCondesed">
                        We are professionals in the field of software
                        development, where we translate creative ideas into
                        workable solutions that promote productivity,
                        creativity, and expansion. Our ability to write code
                        allows us to realize ideas, ultimately shaping
                        technology's future.
                      </p>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link
                        to={"custom-software-development-company-madurai"}
                      >
                        {" "}
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                  {/* =========================== */}
                  <div className="w-full h-[100%] flex flex-col mt-8 bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800 ">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img className="w-16 h-15" src={Coding} alt="loading" />
                      </div>

                      <span className="tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        WEB DEVELOPMENT
                      </span>
                    </div>
                    <div className="flex-grow h-full p-5 pt-0 text-center text-[18px] ">
                      <h3 className="text-justify text-[18px] font-RobatoCondesed">
                        Web development forms the bedrock of your online
                        presence, enabling efficient user interactions and
                        fostering strong client relationships. In the
                        contemporary technological landscape, it stands as an
                        indispensable resource crucial for driving business
                        growth and ensuring a competitive edge.
                      </h3>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link to={"website-designing-company-madurai"}>
                        {" "}
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>{" "}
                      </Link>
                    </div>
                  </div>

                  {/* ====================================== */}
                  <div className="w-full h-[100%] flex flex-col mt-8 text-white bg-gray-900 border-b-4 border-text-gradient rounded-lg shadow-lg shadow-blue-800">
                    <div className="w-full h-auto p-5 ">
                      <div className="mb-[5px] pb-5">
                        {/* <i class="fa-solid fa-code"></i> */}
                        <img
                          className="w-16 h-15"
                          src={AppDevelopment}
                          alt="loading"
                        />
                      </div>

                      <span className="tracking-wider text-[20px] font-bold font-RobatoCondesed">
                        APP DEVELOPMENT
                      </span>
                    </div>
                    <div className="w-full  flex-grow h-full p-5 pt-0 text-center  text-[18px] ">
                      <h3 className="text-justify text-[18px] font-RobatoCondesed">
                        App development is crucial for expanding business reach,
                        enriching customer engagement, and ensuring seamless
                        user experiences. Serving as a cornerstone for digital
                        growth, it represents a strategic move to stay ahead in
                        today's competitive landscape.
                      </h3>
                    </div>
                    <div className="w-full pl-5 py-5 mt-auto">
                      <Link to={"mobile-app-developers-madurai"}>
                        {" "}
                        <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  font-normal ">
                          Read more
                        </button>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="absolute -bottom-2 max-sm:-bottom-1"
            >
              <path
                fill="white"
                fill-opacity="1"
                d="M0,32L30,48C60,64,120,96,180,138.7C240,181,300,235,360,266.7C420,299,480,309,540,266.7C600,224,660,128,720,122.7C780,117,840,203,900,197.3C960,192,1020,96,1080,74.7C1140,53,1200,107,1260,112C1320,117,1380,75,1410,53.3L1440,32L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
              ></path>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="absolute -top-2"
            >
              <path
                fill="white"
                fill-opacity="1"
                d="M0,32L30,48C60,64,120,96,180,138.7C240,181,300,235,360,266.7C420,299,480,309,540,266.7C600,224,660,128,720,122.7C780,117,840,203,900,197.3C960,192,1020,96,1080,74.7C1140,53,1200,107,1260,112C1320,117,1380,75,1410,53.3L1440,32L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
              ></path>
            </svg>
          </div>
        </section>
        {/* ------------------------------------ WHY CHOOSE US -- SECTION  ---------------------- */}
        <section>
          <div className="relative mt-20 mb-10 sec-three">
            <div className="absolute right-0 -top-40 max-md:static md:hidden lg:block">
              <img
                className="object-cover w-full max-md:h-auto "
                src={priviw}
                alt="loading"
              />
            </div>

            <section>
              <div className="bg-white ">
                <h1 className="justify-center text-center text-gray-400 uppercase font-RobatoCondesed ">
                  Why Choose Us ?
                </h1>
                <h3 className="justify-center  font-RobatoCondesed text-center mt-3 text-[28px] font-normal">
                  Here are a few{" "}
                  <span className="text-[32px] text-gradient font-sevenhundered font-RobatoCondesed">
                    REASONS{" "}
                  </span>{" "}
                  why <br /> peoples choose Us{" "}
                </h3>
              </div>
            </section>

            <section className="px-10 py-6 max-sm:px-4">
              <div className="flex flex-col justify-center p-4 ">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-1">
                  <div className=" w-full max-lg:w-full h-[100%] p-3 duration-300 ease-in-out shadow-lg shadow-gray-400">
                    <div className="flex items-center ">
                      {" "}
                      <div className="text-[36px] font-sevenhundered p-2 text-gradient ">
                        01
                      </div>{" "}
                      <span className="ml-5 text-xl font-medium font-RobatoCondesed">
                        Affordable Prices
                      </span>
                    </div>

                    <div className="p-2">
                      <p className="text-justify font-RobatoCondesed">
                        Affordable prices are competitive rates that provide
                        excellent value for your investment. They ensure
                        cost-effectiveness while maintaining high-quality
                        products or services.
                      </p>
                    </div>
                  </div>

                  <div className="p-3 duration-300 ease-in-out shadow-lg shadow-gray-400">
                    <div className="flex items-center ">
                      {" "}
                      <div className="text-[36px] font-sevenhundered p-2 text-gradient">
                        02
                      </div>{" "}
                      <span className="ml-5 text-xl font-medium font-RobatoCondesed">
                        On-Time Delivery
                      </span>
                    </div>

                    <div className="px-2">
                      <p className="text-justify font-RobatoCondesed">
                        We promote timely delivery as one of our core values.
                        Ensuring that our clients routinely receive their
                        products or services on schedule and achieving project
                        deadlines are our top priorities. Our dedication to
                        on-time delivery shows our dependability and commitment
                        to client pleasure.
                      </p>
                    </div>
                  </div>

                  <div className="p-3 ease-in-out shadow-lg shadow-gray-400 duration-400">
                    <div className="flex items-center">
                      {" "}
                      <div className="text-[36px] font-sevenhundered p-2 text-gradient">
                        03
                      </div>
                      <div className="p-4">
                        <span className="text-xl font-medium font-RobatoCondesed">
                          Quality Assurance
                        </span>
                      </div>
                    </div>
                    <p className="text-justify font-RobatoCondesed">
                      We prioritize quality assurance within our work process,
                      implementing stringent measures to ensure that our results
                      consistently meet high standards.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <section>
                <div className="flex h-auto px-10 mt-10 bg-white max-md:flex-col">
                  <div className=" w-[100%] h-[400px]">
                    <img
                      className="object-contain w-full h-full"
                      src="https://img.freepik.com/free-vector/illustration-theme-with-people-meeting_52683-26608.jpg?size=626&ext=jpg&uid=R102333892&ga=GA1.2.1045539105.1687668655&semt=ais"
                      alt=""
                    />
                  </div>
                  <div className="w-[100%] h-auto">
                    <h1 className="text-[23px] text-gradient  font-RobatoCondesed  ">
                      Key Features
                    </h1>

                    <p className="mt-5 text-justify font-RobatoCondesed">
                      Enhance your online presence with our website design
                      company in Madurai, which is packed with a range of
                      essential features for engaging and converting visitors.
                    </p>
                    <div className="mt-5">
                      <ul>
                        <li className="flex mb-2">
                          <img
                            className="mt-2 mr-2 h-7 w-7"
                            src={Checkbox}
                            alt=""
                          />
                          <p className="font-RobatoCondesed">
                            One size does not fit all. Our team of talented
                            designers creates unique, tailor-made designs that
                            reflect your brand identity.
                          </p>
                        </li>
                        <li className="flex mb-2">
                          <img className="mr-2 h-7 w-7" src={Checkbox} alt="" />
                          <p className="font-RobatoCondesed">
                            It is important that websites be responsive in a
                            world where users visit them from a variety of
                            devices.
                          </p>
                        </li>
                        <li className="flex ">
                          <img className="mr-2 h-7 w-7" src={Checkbox} alt="" />
                          <p className="font-RobatoCondesed">
                            Take control of your website with our user-friendly
                            Content Management System. Update content, add
                            images, and make changes on the fly without the need
                            for technical expertise
                          </p>
                        </li>

                        <li>
                          <Link to={"digital-marketing-in-madurai"}>
                            {" "}
                            <button class="btn-hover color-2 mt-10 pt-2 pb-2 pl-8 pr-8">
                              Read more
                            </button>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </section>
        {/* ------------------------------------  How We Do -- SECTION  ---------------------- */}
        <section>
          <div className="relative mt-5 mb-60">
            <div className="pt-10 pb-32 sec-four ">
              <div className="h-auto w-[full]  max-md:px-5   text-justify   mb-8 px-12 max-sm:px-4">
                <h1 className="text-[32px] font-RobatoCondesed uppercase pb-4 text-center">
                  How do we{" "}
                  <span className="text-[32px] text-gradient font-serif">
                    create value{" "}
                  </span>{" "}
                  to your business?{" "}
                </h1>
                <p className="w-[90%] font-RobatoCondesed max-sm:w-full  text-center px-10  max-sm:text-justify max-md:px-0 justify-center   ">
                  Through strategic experience and perfect execution, Honey
                  Iconics shapes web development success, ensuring that your
                  online presence crosses expectations and provides amazing
                  results.
                </p>
              </div>
              <section>
                <div className="w-[100%]  h-auto  flex max-sm:px-4  px-10 max-md:flex-col ">
                  <section className="">
                    <section className="py-6 ">
                      <div className="container flex flex-col justify-center p-4 ">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 sm:grid-cols-2">
                          <div className="flex flex-col h-full bg-white shadow-2xl rounded-2xl">
                            <div className="flex-grow p-5">
                              <h1 className="pb-3 font-RobatoCondesed text-[22px] text-gradient font-medium">
                                Innovative Web Design
                              </h1>
                              <p className="text-justify font-RobatoCondesed">
                                Discover the leading trend in web creation at
                                FindWay Digital, where creativity and
                                innovation combine. Our custom solutions offer a
                                unique combination of design and functionality,
                                redefining online experiences. Please put your
                                confidence in us to create a memorable website
                                that helps your company succeed.
                              </p>
                            </div>
                            <div className="p-5 mt-auto">
                              <Link
                                to={
                                  "custom-software-development-company-madurai"
                                }
                              >
                                {" "}
                                <button className="pt-1 pb-1 pl-6 pr-6 btn-hover color-2">
                                  Read more
                                </button>{" "}
                              </Link>
                            </div>
                          </div>
                          {/* ========================== */}

                          <div className="flex flex-col h-full bg-white shadow-2xl rounded-2xl">
                            <div className="flex-grow p-5">
                              <h1 className="pb-3 text-[22px]  font-RobatoCondesed text-gradient font-medium">
                                Develop a responsive layout
                              </h1>
                              <p className="text-justify font-RobatoCondesed">
                                Use our services for responsive web development
                                to improve your internet visibility. Our design
                                puts the user experience first and smoothly
                                adjusts to all devices. Join us as we use
                                creative and flexible designs to help you shape
                                the course of your digital trip.
                              </p>{" "}
                            </div>

                            <div className="p-5 mt-auto">
                              <Link to={"website-designing-company-madurai"}>
                                {" "}
                                <button className="pt-1 pb-1 pl-6 pr-6 btn-hover color-2">
                                  Read more
                                </button>{" "}
                              </Link>
                            </div>
                          </div>
                          {/* ========================== */}
                          <div className="flex flex-col h-full bg-white shadow-2xl rounded-2xl">
                            <div className="flex-grow p-5">
                              {" "}
                              <h1 className="pb-3 font-RobatoCondesed text-[22px]   text-gradient font-medium">
                                Systematized Informational
                              </h1>
                              <p className="text-justify font-RobatoCondesed">
                                Experience the efficiency of logical
                                informational web development, which ensures an
                                organized and successful website. Our solutions
                                put a priority on design and clarity, giving
                                those who visit your website a better online
                                experience.
                              </p>{" "}
                            </div>
                            <Link to={"mobile-app-developers-madurai "}>
                              <div className="p-5 mt-auto">
                                <button className="pt-1 pb-1 pl-6 pr-6 btn-hover color-2">
                                  Read more
                                </button>
                              </div>
                            </Link>
                          </div>
                          {/* ========================== */}
                          <div className="flex flex-col h-full bg-white shadow-2xl rounded-2xl">
                            <div className="flex-grow p-5">
                              {" "}
                              <h1 className="pb-3 font-RobatoCondesed text-[22px]   text-gradient font-medium">
                                Set Goals
                              </h1>
                              <p className="text-justify font-RobatoCondesed">
                                We think that objectives need to be as unique as
                                the people or organizations who set them. Our
                                staff works closely with you to get to know your
                                goals, challenges, and goals in order to create
                                an approach that meets your unique requirements.
                              </p>{" "}
                            </div>

                            <div className="p-5 mt-auto">
                              <Link to={"digital-marketing-in-madurai"}>
                                {" "}
                                <button className="pt-1 pb-1 pl-6 pr-6 btn-hover color-2">
                                  Read more
                                </button>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </section>

                  <section className="w-[50%] max-md:w-full  h-full md:flex-col md:hidden lg:block">
                    <div className="w-full h-auto ">
                      <img
                        className="object-cover w-full h-full"
                        src={Imagehang}
                        alt=""
                      />
                    </div>
                  </section>
                </div>
              </section>
            </div>

            <section className="absolute -bottom-[100px] w-full px-10  max-sm:px-0">
              <div className="w-full  h-auto  btn-hover.color-2   shadow-2xl bg-slate-900 text-white rounded-3xl  ">
                <ul className="flex gap-4 p-5 text-center justify-evenly">
                  <li>
                    <div className="">
                      <span className="text-[45px] font-medium max-sm:text-[15px]">
                        18+
                      </span>
                      <p className="text-xl max-sm:text-sm">
                        Years in business
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="">
                      <span className="text-[45px] font-medium max-sm:text-[15px]">
                        250+
                      </span>
                      <p className="text-xl max-sm:text-sm">
                        Websites & projects launched
                      </p>
                    </div>
                  </li>
                  <div className="">
                    <span className="text-[45px] font-medium max-sm:text-[15px]">
                      30+
                    </span>
                    <p className="text-xl max-sm:text-sm">
                      Awards & recognition
                    </p>
                  </div>
                </ul>
              </div>
            </section>
          </div>
        </section>

        {/* -------------------------------------- Our tecs  list -- SECTION ------------------------ */}
        <section>
          <div>
            <div className="w-full h-auto ">
              <div className="w-full h-auto p-5 mb-10 last">
                <section>
                  <div className="">
                    <h1 className="text-[35px] p-5 font-RobatoCondesed max-md:text-[20px] text-white font-sevenhundered">
                      We can develop creative ideas that support the continuous
                      development of businesses by analyzing a wide range of
                      technologies.
                      <span className="text-gradient">
                        we are the  artists.
                      </span>
                    </h1>
                    <p className="text-[17px] p-5 font-RobatoCondesed text-white font-medium mt-3">
                      We use the power of technology and make websites on all
                      major CMS and frameworks.
                    </p>
                  </div>
                  <div>
                    <section className="py-6 ">
                      <div className="container flex flex-col justify-center mx-auto">
                        <div className="grid grid-cols-3 gap-14 lg:grid-cols-5 sm:grid-cols-2">
                          <center>
                            <img src={ReactImage} alt="loading" />
                          </center>

                          <center>
                            <img src={AngulerImage} alt="loading" />
                          </center>

                          <center>
                            <img src={VueImage} alt="loading" />
                          </center>

                          <center>
                            <img src={HtmlImage} alt="loading" />
                          </center>

                          <center>
                            <img src={Cssimage} alt="loading" />
                          </center>

                          <center>
                            <img src={JavascriptImage} alt="loading" />
                          </center>

                          <center>
                            <img src={JqueryImage} alt="loading" />
                          </center>

                          <center>
                            <img src={BootStrapImage} alt="loading" />
                          </center>

                          <center>
                            <img src={NpmImage} alt="loading" />
                          </center>

                          <center>
                            <img src={WordpressImage} alt="loading" />
                          </center>

                          <center>
                            <img src={WooCommerceImage} alt="loading" />
                          </center>

                          <center>
                            <img src={Laravel} alt="loading" />
                          </center>

                          <center>
                            <img src={Mongodb} alt="loading" />
                          </center>

                          <center>
                            <img src={Node} alt="loading" />
                          </center>

                          <center>
                            <img src={PHPImage} alt="loading" />
                          </center>

                          <center>
                            <img src={Python} alt="loading" />
                          </center>

                          <center>
                            <img src={Mobile} alt="loading" />
                          </center>

                          <center>
                            <img src={Andriod} alt="loading" />
                          </center>
                        </div>
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/* ---------------------------- Portfolio ----------------------------*/}
        <section>
          <div className="font-RobatoCondesed text-[18px] ">
            <div>
              <h1 className="text-[35px] font-RobatoCondesed font-semibold text-center text-gradient">
                Our Portfolio{" "}
              </h1>
              <p className="text-xl font-thin text-center ">
                Our Latest Work For Your Business{" "}
              </p>
            </div>
            <div className="box">
              <center>
                <div className="tabs ">
                  <div
                    onClick={() => action(1)}
                    className={`${state === 1 ? "tab active-tab" : "tab"}`}
                  >
                    All Works
                  </div>
                  <div
                    onClick={() => action(2)}
                    className={`${state === 2 ? "tab active-tab" : "tab"}`}
                  >
                    Tours
                  </div>
                  <div
                    onClick={() => action(3)}
                    className={`${state === 3 ? "tab active-tab" : "tab"}`}
                  >
                    Interior
                  </div>
                  <div
                    onClick={() => action(4)}
                    className={`${state === 4 ? "tab active-tab" : "tab"}`}
                  >
                    Catring
                  </div>
                  {/* <div
                    onClick={() => action(5)}
                    className={`${state === 5 ? "tab active-tab" : "tab"}`}
                  >
                    Tab-5
                  </div> */}
                </div>{" "}
              </center>

              <div className=" contents">
                <div
                  className={`${
                    state === 1 ? "content active-content" : "content"
                  } `}
                >
                  <section className="py-6 ">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                      <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={FirstImage}
                          alt="loading"
                        />
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={SecondImage}
                          alt="loading"
                        />
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageone}
                          alt="loading"
                        />

                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageFive}
                          alt="loading"
                        />

                        <img
                          className="object-contain w-full dark:bg-gray-500 aspect-square"
                          src={PortImageCateringOne}
                          alt="loading"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div
                  className={`${
                    state === 2 ? "content active-content" : "content"
                  }`}
                >
                  <section className="py-6 ">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                      <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-2">
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortToursOne}
                          alt="loading"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div
                  className={`${
                    state === 3 ? "content active-content" : "content"
                  }`}
                >
                  <section className="py-6 ">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                      <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-2">
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageInteriorOne}
                          alt="loading"
                        />
                      </div>
                    </div>
                  </section>
                </div>

                <div
                  className={`${
                    state === 4 ? "content active-content" : "content"
                  }`}
                >
                  <section className="py-6 ">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                      <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 sm:grid-cols-2">
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageCateringOne}
                          alt="loading"
                        />
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageCateringTwo}
                          alt="loading"
                        />
                        <img
                          className="object-cover w-full dark:bg-gray-500 aspect-square"
                          src={PortImageCateringThree}
                          alt="loading"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -------------------------------------- What our client says -- SECTION ------------------------ */}
        <section>
          <div className="p-5 bg-gray-100">
            <div className="flex w-full h-auto py-5 pl-10 bg-white max-md:flex-col max-md:p-4 rounded-3xl">
              <div className="flex items-center h-full gap-2 px-8 py-8 shadow-xl w-80 rounded-3xl shadow-gray-400 max-md:w-full max-md:justify-center ">
                <div className="w-auto h-auto mb-16 align-top">
                  <img className="h-10 " src={Google} alt="" />
                </div>
<a href=""></a>
                <div className="w-auto h-auto pr-8 ">
                  <h1 className="pl-1">Google Rating</h1>
                  <div>
                    <ul className="flex pl-1">
                      <ul className="mr-1 text-xl font-semibold">5</ul>
                      <li className="text-gradient">
                        <i class="fa-solid fa-star"></i>
                      </li>
                      <li className="text-gradient">
                        <i class="fa-solid fa-star"></i>
                      </li>
                      <li className="text-gradient">
                        <i class="fa-solid fa-star"></i>
                      </li>
                      <li className="text-gradient">
                        <i class="fa-solid fa-star"></i>
                      </li>
                      <li className="text-gradient">
                        <i class="fa-solid fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <p className="pl-1">Based on 111 reviews</p>

                  <div className="flex w-auto h-auto px-3 py-1 mt-3 mr-5 font-medium text-white bg-blue-600 text-[15px] rounded-3xl">
                    Review us on{" "}
                    <img
                      className="h-6 ml-2 bg-white rounded-full"
                      src={Google}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="h-auto pl-10  w-[70%] md:w-[50%] max-md:w-full text-justify max-md:p-4">
                <p className="text-[32px] font-RobatoCondesed font-sevenhundered">
                  <span className="text-gradient">What Our</span> Clients saying
                  About FindWay Digital
                </p>
                <p className="mt-3 font-RobatoCondesed text-text-justify text-[20px]">
                  Our constant commitment to customer satisfaction leads to a
                  committed and happy client.
                </p>
              </div>
            </div>
            <div className="p-5 mt-5 h-full bg-white rounded-3xl">
              <OwlCarousel className="owl-theme " {...options}>
                <div className="w-auto p-5 ">
                  <div className=" h-full flex flex-col justify-between p-5 shadow-md shadow-gray-400 rounded-3xl">
                    <div className="flex  h-full">
                      <div className="pt-3 text-center bg-green-600 rounded-full h-14 w-14 ">
                        <span className="text-2xl text-white font-sevenhundered">
                          v
                        </span>
                      </div>
                      <div className="pl-3 ">
                        <h1>Vishwa</h1>
                        <span className="font-thin">5 days ago</span>
                      </div>
                    </div>

                    <div className="mt-3 ml-2">
                      <ul className="flex text-xl">
                        <ul className="mr-1 text-2xl font-bold">5.0</ul>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                      </ul>
                    </div>

                    <p className=" mt-5 ml-2 h-full flex-grow text-justify ">
                      Choosing FindWay Digital for web design and digital
                      marketing services in Madurai was a game-changer for
                      us. Their ability to seamlessly blend design and digital
                      marketing into a cohesive strategy has given us a
                      competitive advantage. We couldn't be happier with their
                      services.
                    </p>
                  </div>
                </div>

                <div className="w-auto font-RobatoCondesed h-full p-5 ">
                  <div className="w-auto h-full flex flex-col justify-between p-5 shadow-md shadow-gray-400 rounded-3xl">
                    <div className="flex w-full h-auto">
                      <div className="pt-3 text-center bg-green-600 rounded-full h-14 w-14 ">
                        <span className="text-2xl text-white font-sevenhundered">
                          P
                        </span>
                      </div>
                      <div className="pl-3 ">
                        <h1> Pavithran Nagarajan</h1>{" "}
                        <span className="font-thin">one month ago</span>
                      </div>
                    </div>
                    <div className="mt-3 ml-2">
                      <ul className="flex text-xl">
                        <ul className="mr-1 text-2xl font-bold">5.0</ul>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                      </ul>
                    </div>
                    <h3 className="mt-5 h-full flex-grow ml-2 text-justify">
                      FindWay Digital has truly earned its reputation as the
                      best web design company in Madurai. Their team's
                      creative genius and technical prowess transformed our
                      vision into an exceptional website. We're thrilled with
                      the results
                    </h3>
                  </div>
                </div>

                <div className="w-auto  p-5 ">
                  <div className="w-auto flex flex-col justify-between h-auto p-5 shadow-md shadow-gray-400 rounded-3xl">
                    <div className="flex w-full h-auto">
                      <div className="pt-3 text-center bg-green-600 rounded-full h-14 w-14 ">
                        <span className="text-2xl text-white font-sevenhundered">
                          S
                        </span>
                      </div>
                      <div className="pl-3 ">
                        <h1>Sudharshan M</h1>{" "}
                        <span className="font-thin"> 15 days ago</span>
                      </div>
                    </div>

                    <div className="mt-3 ml-2">
                      <ul className="flex text-xl">
                        <ul className="mr-1 text-2xl font-bold">5.0</ul>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                        <li className="mr-1 text-gradient">
                          <i class="fa-solid fa-star"></i>
                        </li>
                      </ul>
                    </div>

                    <h3 className="mt-5 ml-2 text-justify">
                      I'm so impressed with FindWay Digital. Their status as
                      the best web design company in Madurai is
                      well-deserved. They've not only met but exceeded our
                      expectations with their top-notch designs and exceptional
                      customer service
                    </h3>
                  </div>
                </div>
              </OwlCarousel>{" "}
            </div>{" "}
          </div>
        </section>
        {/* -------------------------------------- Faq -- SECTION ------------------------ */}
        <section>
          <div>
            <section className="mb-5 dark:text-gray-100 sec-four">
              <div className="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
                <h2 className="text-2xl font-semibold font-RobatoCondesed sm:text-4xl ">
                 
                    Frequently{" "}
                 
                  Asked Questions
                </h2>
                {/* <p className="mt-4 mb-8 font-medium font-RobatoCondesed ">
              
                </p> */}
                <div className="mt-5 space-y-4 ">
                  <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                    <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                      What does FindWay Digital specialize in ?
                    </summary>
                    <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 ">
                      FindWay Digital is a leading website development company
                      based in Madurai. We specialize in a range of digital
                      services, including website development, SEO (search
                      engine optimization), logo design, etc.
                    </p>
                  </details>
                  <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                    <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                      Can you tell us more about your website development
                      services?
                    </summary>
                    <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                      Certainly! We take pride in crafting custom websites
                      tailored to the unique needs of our clients. Our team of
                      experienced developers ensures that your website is not
                      only visually appealing but also highly functional and
                      user-friendly.
                    </p>
                  </details>
                  <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                    <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                      What is SEO, and how does FindWay Digital help businesses
                      with it?
                    </summary>
                    <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                      SEO, or Search Engine Optimization, is the process of
                      optimizing your website to rank higher in search engine
                      results. At FindWay Digital, we provide SEO services that
                      improve your website's visibility, driving organic traffic
                      and helping you reach your target audience effectively.
                    </p>
                  </details>
                  <details className="w-full text-white rounded-lg shadow-xl bg-slate-900">
                    <summary className="px-4 py-6 duration-100 ease-in-out font-RobatoCondesed focus:outline-none focus-visible:ri">
                      Do you offer logo design services as well?
                    </summary>
                    <p className="px-4 py-6 pt-5 mx-4 mt-4 border-t-2 font-RobatoCondesed ">
                      Yes, we do! Our skilled designers create memorable and
                      impactful logos that represent your brand's identity. A
                      well-designed logo can leave a lasting impression on your
                      audience, and we're here to ensure it's a positive one.
                    </p>
                  </details>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* -------------------------------------- Form -- SECTION ------------------------ */}
        <section>
          <div className="flex p-10 max-sm:p-3 max-md:flex-col">
            <div className="w-[50%] max-md:w-full">
              <form onSubmit={handleFormSubmit}>
                <div className="p-10 space-y-12 bg-white border-2 ">
                  <div className="pb-12 ">
                    <h2 className="text-3xl leading-7 font-RobatoCondesed font-sevenhundered ">
                      SHARE YOUR IDEA OR{" "}
                      <span className="text-gradient">REQUIREMENTS </span>
                    </h2>
                    <p className="mt-5 text-lg font-medium leading-6 font-RobatoCondesed text-slate-900">
                      Use a permanent address{" "}
                      <span className="text-gradient">
                        where you can receive Message.{" "}
                      </span>
                    </p>

                    <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-full">
                        <label
                          htmlFor="name"
                          className="block font-semibold leading-6 text-md text-slate-950"
                        >
                          Name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="block w-full bg-gray-50 py-1.5 text-black border-[1px] border-gray-300 px-3   sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-full">
                        <label
                          htmlFor="email"
                          className="block font-semibold leading-6 text-md text-slate-950"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            autoComplete="email"
                            className="block w-full  bg-gray-50 py-1.5 text-black border-[1px] border-gray-300 px-3   sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="last-name"
                          className="block font-semibold leading-6 text-md text-slate-950"
                        >
                          Message
                        </label>
                        <div className="mt-2">
                          <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            rows="4"
                            cols="50"
                            className="block w-full bg-gray-50  py-1.5 text-black border-[1px] border-gray-300 px-3   sm:text-sm sm:leading-6"
                          ></textarea>{" "}
                        </div>
                      </div>
                    </div>
                    <button class="btn-hover color-2  pt-1 pb-1 pl-5 pr-5  w-[35%] text-3xl mt-10 max-sm:w-[50%]">
                      <span className="font-semibold">Submit</span>
                    </button>
                  </div>
                </div>
              </form>{" "}
            </div>
            <div className="w-[50%] max-md:hidden">
              {" "}
              <img
                className="object-cover h-full ml-2 "
                src={Image}
                alt="loading"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
