import React from "react";
import ScrolToTopBtn from "../Scrollings/btn-scroll-to-top";
import Logo from "../../images/Logo/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div>
        <div className="w-full h-auto px-10 py-4 bg-slate-900 ">
          <div className="flex flex-wrap lg:justify-around items-start gap-10">
            {" "}
            <div className="w-auto h-auto lg:mr-5 bg-wh">
              <h1 className="text-[25px]  text-white font-sevenhundered">
                Quick Links
              </h1>

              <div>
                <ul className="mt-8  text-white font-normal text-[14px]">
                  <li>
                    <Link to={"/about-us"}>
                      {" "}
                      <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      ABOUT US
                    </Link>
                  </li>
                  <li className="mt-5">
                    <Link to={"/our-work"}>
                      {" "}
                      <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      OUR WORK{" "}
                    </Link>
                  </li>
                  <li className="mt-5">
                    <Link to={"/blog"}>
                    <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      BLOG
                    </Link>

                  </li>
                  <li className="mt-5">
                  <span className="text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    CARRER
                  </li>
                </ul>
              </div>
            </div>{" "}
            {/* ---- */}
            <div className="w-auto h-auto lg:pl-10 mr-5 ">
              <h1 className="text-[25px]  text-white font-sevenhundered">
                Service
              </h1>

              <div >
                <ul className="mt-8 text-white  font-normal text-[14px]">
                  <li>
                    <Link to={"/digital-marketing-in-madurai"}>   <span className="text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                      Digital Marketing </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/website-designing-company-madurai"}>   <span className="text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                      Web Development </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/mobile-app-developers-madurai"}>  <span className="text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>{" "}
                      Mobile App Development{" "} </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/custom-software-development-company-madurai"}>
                      <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Custom Software Development
                    </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/local-page-seo"}>
                    <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      Local SEO
                    </Link>

                  </li>
                  <li className="mt-5">
                    <Link to={"/on-page-seo"}>
                    <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>
                      ON Page SEO
                    </Link>

                  </li>

                  <li className="mt-5">
                    <Link to={"/off-page-seo"}>
                    <span className="text-white">
                        <i class="fa-solid fa-angles-right"></i>{" "}
                      </span>{" "}
                      OFF Page SEO
                    </Link>

                  </li>

                </ul>
              </div>
            </div>{" "}
            <div className="h-auto lg:pl-16 mr-5 w-80">
              <h1 className="text-[25px]  text-white pt-0 font-sevenhundered">
                Branches
              </h1>

              <div>
                <ul className="mt-8 text-white font-normal text-[14px] ">
                  <li className="flex mt-5">

                  <span className="mr-1  text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>  
                     N0-5,1st floor,Sakthi Velammal Street,<br/>                     
                     Bank of Baroda Upstairs,S.S.Colony,<br/>
                     Coimbatore -641044             
                  </li>

                  <li className="flex mt-5">

<span className="mr-1  text-white">
    <i class="fa-solid fa-angles-right"></i>{" "}
  </span>  
  NO-204A,<br/>                     
                Near G.P Signal New Sidhapudur,<br/>                 
                madurai-625017	           
</li>
                  {/* <li className="flex mt-5">


                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>


                    No: 97 Sova Appartment, B-Block,kalyani, West Bengal-75423.


                  </li>
                  <li className="flex mt-5">

                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>

                    Paras global kutir, Pattanagere road Rajarajeshwari Nagar,Bengaluru-560098.



                  </li>
                  <li className="flex mt-5">
                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    Malleswaran.P, No 583, B1,6 th cross Ring Road, anjaneyar Temple street, Muneeswar nagar, Hosur, TAMIL NADU 635109

                  </li>
                  <li className="flex mt-5">
                    <span className="mr-1 text-orange-500">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    Indira Nagar, Siva Salai, SP Bunglow Opp, Devarasampatti, Dharmapuri - 636807

                  </li> */}
                </ul>
              </div>
            </div>{" "}
            <div className="h-auto w-72 lg:pl-5">
              <h1 className="text-[25px]  text-white font-sevenhundered">
                Follow us
              </h1>

              <div>
                <ul className="mt-8 text-white font-normal text-[14px]">
                  <li className="flex mt-5">
                    {" "}
                    <span className="text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a href="" target="_blank">
                     
                NO-204A,<br/>                     
                Near G.P Signal New Sidhapudur,<br/>                 
                madurai-625017	

                  {/* N0-5,1st floor,Sakthi Velammal Street,<br/>                     
                     Bank of Baroda Upstairs,S.S.Colony,<br/>
                     Coimbatore -641044 				 */}
                      </a>

                  </li>
                  <li className="flex flex-wrap mt-5">
                    {" "}
                    <span className="mr-1 text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a href="mailto:info@honeyiconics.com" target="_blank">info@findway.com</a>
                    <a href="mailto:support@honeyiconics.com" target="_blank" className="pl-5">support@findway.com</a>
                  </li>
                  <li className="flex mt-5">
                    {" "}
                    <span className="mr-1 text-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a href="tel:+917538857567 " className="mr-4" target="_blank"> +91 75388 57567 
                    </a>
                    {/* <a href="tel:+918608638406" target="_blank"> +91 86086 38406
                   </a> */}
                  </li>
                  <li className="flex mt-5">
                    {" "}
                    <span className="mr-1 tex-white">
                      <i class="fa-solid fa-angles-right"></i>{" "}
                    </span>
                    <a href="tel:+918608638406" target="_blank">+91 98845 65567</a>

                  </li>
                </ul>
              </div>
            </div>{" "}
          </div>
        </div>
        {/* ---- */}
        <div className="w-full h-auto px-10 bg-gray-950">
          <div className="flex justify-between py-5 max-sm:flex-col">
            <div className="w-[30%] h-auto  max-sm:w-full">
              <Link to={"https://honeyiconics.com/"}><img
                className="object-contain w-full h-10"
                src={Logo}
                alt="loading"
              /></Link>

            </div>
            {/* -- */}
            <div className="flex w-full h-auto pl-10 text-justify max-sm:pl-3 max-sm:pr-3 text-cenetr gap-7 max-sm:flex-col">
              <p className="flex flex-wrap justify-center pt-2 text-white max-sm:text-sm">
                <div className="max-sm:ml-3">
                  {" "}
                  Copyright © 2018 <Link to={"https://honeyiconics.com/"}>FindWay</Link>{" "}
                </div>
                <div>
                  {" "}
                  <span className="ml-5 mr-5 "> |</span> All Rights Reserved.{" "}
                </div>
                <div>
                  {" "}
                  <span className="ml-5 mr-5">|</span>Privacy Policy
                </div>
              </p>
              <div>
                <div className="justify-center w-full h-auto pt-1 pl-16 max-sm:pl-[30%]">
                  <ul className="flex gap-3 ">
                    <a href="https://www.facebook.com/profile.php?id=100083048122871">
                    <li>
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <i class="fa-brands fa-facebook"></i>{" "}
                        </div>
                      </div>
                    </li>
                    </a>
                    {/* --- */}
                 
                    <li>
                    <a href="https://www.instagram.com/findwaydigitalmarketing/">
                      <div className="w-10 h-10 ">

                        <div className="text-2xl ">
                          {" "}
                          <i class="fa-brands fa-instagram"></i>
                        </div>
                      </div>
                      </a>
                    </li>
                    {/* -- */}
                    <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fashokraana123">
                    <li>

                   
                  
                      {" "}
                      <div className="w-10 h-10 ">
                        <div className="text-2xl ">
                          {" "}
                          <i class="fa-brands fa-x-twitter"></i>
                        </div>
                      </div>
                    </li>
                    </a>

                    <a href="https://www.linkedin.com/company/86457806/admin/feed/">
                    <li>
  <div className="w-10 h-10">
    <div className="text-2xl">
    <i class="fa-brands fa-linkedin-in"></i>
    </div>
  </div>
</li></a>

<a href="https://in.pinterest.com/ashok7036/">
<li>
  <div className="w-10 h-10">
    <div className="text-2xl">
    <i class="fa-brands fa-pinterest"></i>
    </div>
  </div>
</li>
</a>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----- */}
        <ScrolToTopBtn />
      </div>
    </>
  );
};

export default Footer;
