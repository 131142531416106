import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../images/Logo/logo.png";
const headerone = () => {
  const head = [
    {
      name: "HOME",
      id: 1,
      cLassname: "cname",
      link: "/",
    },
    {
      name: "ABOUT US",
      id: 2,
      cLassname: "cname",
      link: "/about-us",
    },
    { name: "OUR WORK", id: 3, classname: "cname", link: "/our-work" },

    { name: "CONTACT", id: 5, classname: "cname", link: "/contact" },
  ];

  return (
    <>
      {/* bg-[#2A2836] */}
      <nav className="flex justify-between w-full h-auto p-5 md:bg-gray-950 bg-slate-900">
        <div>
          <Link to={"/"}>     <img
            className="h-10"
            src={Logo}  alt="loading"
          /></Link>
     
        </div>

        <div className="flex mr-16 max-md:hidden">
          {head.map((item) => (
            <div className="">
              <ul className="text-white cursor-pointer ">
                <li
                  key={item.name}
                  className="font-normal mr-4 text-xs     text-justify mt-1.5  w-auto  flex"
                >
                  <Link to={item.link}> {item.name}</Link>

                  <div className="h-2 w-[1px] mt-1 ml-2 bg-white"></div>
                </li>
              </ul>
            </div>
          ))}
          <Link to={"contact"}>      <button className="h-auto px-3 py-2 rounded-lg font-mono text-xs text-center text-white bg-cherry bg-gradient-to-r from-cherry-red-two to-cherry-red ">
            Get Quote
          </button> </Link>
    
        </div>
      </nav>
      {/* ------------------------ */}
    </>
  );
};

export default headerone;
