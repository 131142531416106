import React from "react";

const BlogCompo = (props) => {
  return (
    <>
      <div className="rounded-md shadow-md max-w-screen-2xl dark:text-gray-100">
        <img
          src={props.img}
          alt="loading"
          className="object-cover object-center w-full rounded-t-md h-86 dark:bg-gray-500"
        />
        <div className="flex flex-col justify-between p-6 space-y-8 text-black">
          <div className="space-y-2">
            <h2 className="font-semibold text-1xl font-Robato">{props.heading}</h2>
            <p className="mt-10 text-black">{props.para} </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCompo;
